export const resetModals = () => {
  return {
    type: 'RESET_MODALS',
  }
}

export const setLoginModalVisibility = visibility => {
  return {
    type: 'SET_LOGIN_MODAL_VISIBILITY',
    payload: visibility,
  }
}

export const setSignupModalVisibility = visibility => {
  return {
    type: 'SET_SIGNUP_MODAL_VISIBILITY',
    payload: visibility,
  }
}

export const setCreditCardModalVisibility = visibility => {
  return {
    type: 'SET_CREDIT_CARD_MODAL_VISIBILITY',
    payload: visibility,
  }
}

export const setBuyModalVisibility = visibility => {
  return {
    type: 'SET_BUY_MODAL_VISIBILITY',
    payload: visibility,
  }
}

export const setRequestViewingModalVisibility = visibility => {
  return {
    type: 'SET_REQUEST_VIEWING_MODAL_VISIBILITY',
    payload: visibility,
  }
}

export const setViewingReviewModalVisibility = visibility => {
  return {
    type: 'SET_VIEWING_REVIEW_MODAL_VISIBILITY',
    payload: visibility,
  }
}

export const setBuildingReviewModalVisibility = visibility => {
  return {
    type: 'SET_BUILDING_REVIEW_MODAL_VISIBILITY',
    payload: visibility,
  }
}

export const setForgotPasswordModalVisibility = visibility => {
  return {
    type: 'SET_FORGOT_PASSWORD_MODAL_VISIBILITY',
    payload: visibility,
  }
}

export const setContactModalVisibility = visibility => {
  return {
    type: 'SET_CONTACT_MODAL_VISIBILITY',
    payload: visibility,
  }
}

export const setListingPhotoCarouselModalVisibility = visibility => {
  return {
    type: 'SET_LISTING_PHOTO_CAROUSEL_MODAL_VISIBILITY',
    payload: visibility,
  }
}

export const setRentalMoreFiltersModalVisibility = visibility => {
  return {
    type: 'SET_RENTAL_MORE_FILTERS_MODAL_VISIBILITY',
    payload: visibility,
  }
}

export const setListingShareModalVisibility = visibility => {
  return {
    type: 'SET_LISTING_SHARE_MODAL_VISIBILITY',
    payload: visibility,
  }
}
