import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { routerReducer } from 'react-router-redux'

import modal from './modalReducer'
import neighborhood from './neighborhoodReducer'
import user from './userReducer'
import app from './appReducer'
import broker from './brokerReducer'
import rental from './rentalReducer'
import conversation from './conversationReducer'
import sale from './saleReducer'
import amenity from './amenityReducer'
import subway from './subwayReducer'
import viewing from './viewingReducer'
import article from './articleReducer'
import building from './buildingReducer'
import author from './authorReducer'

export default combineReducers({
  form,
  modal,
  neighborhood,
  user,
  app,
  rental,
  building,
  broker,
  conversation,
  sale,
  amenity,
  subway,
  article,
  viewing,
  author,
  router: routerReducer,
})
