import { Component } from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { matchRoutes, renderRoutes } from 'react-router-config'

import * as actions from './actions'

import routes from './routes'
import errorRoutes from './errorRoutes'

export const fetchData = (store, location) => {
  const pathname = location.split('?')[0]
  const query = location.split('?')[1]
  const branch = matchRoutes(routes, pathname)

  const promises = branch.map(({ route, match }) => {
    if (route.fetchData) {
      return route.fetchData(store, match, query, pathname)
    }

    return null
  })

  return Promise.all(promises)
}

@connect(state => ({
  pageStatus: state.app.pageStatus,
}), actions)
class DataLoader extends Component {
  constructor(props) {
    super(props)

    if (props.status) {
      // this.props.setErrorStatus(props.status)
    }
  }

  static contextTypes = {
    store: object
  }

  static propTypes = {
    location: object.isRequired
  }

  // componentWillReceiveProps(nextProps) {
  componentDidUpdate(prevProps) {
    const navigated = prevProps.location.pathname !== this.props.location.pathname

    if (navigated) {
      this.props.setErrorStatus(null)
      const { store } = this.context

      fetchData(store, this.props.location.pathname + this.props.location.search)
      .catch(err => {
        console.log('ERROR', err)
        // err && !err.isCancel && this.props.setErrorStatus(err.status)
      })
    }
  }

  render() {
    const {
      pageStatus,
    } = this.props

    if (pageStatus === 200) {
      return renderRoutes(routes)
    } else {
      return renderRoutes(errorRoutes)
    }
  }
}

export default withRouter(DataLoader)
