/* global swal */

import { RequestService } from '../store'

const defaultState = {
  user: null,
  userData: null,
  loginFetching: false,
  signupFetching: false,
  sendForgotPasswordFetching: false,
  imageUploadPending: false,
  changePasswordPending: false,
  updateUserPending: false,
  setNewPasswordPending: false,
  setNewPasswordError: false,
  initialSetupPending: false,
  roommates: [],
  roommatesFetching: false,
  roommate: null,
  proUserSuggestionsPending: false,
  proUserSuggestions: [],
}

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case 'SET_USER': {
      state = {
        ...state,
        user: action.payload,
      }

      break
    }
    case 'GET_PRO_USER_SUGGESTIONS_PENDING': {
      state = {
        ...state,
        proUserSuggestionsPending: true,
      }

      break
    }
    case 'GET_PRO_USER_SUGGESTIONS_FULFILLED': {
      state = {
        ...state,
        proUserSuggestions: action.payload,
        proUserSuggestionsPending: false,
      }

      break
    }
    case 'GET_PRO_USER_SUGGESTIONS_REJECTED': {
      state = {
        ...state,
        proUserSuggestions: null,
        proUserSuggestionsPending: false,
      }

      break
    }
    case 'ADD_TO_NEWSLETTER_PENDING': {
      state = {
        ...state,
        addToNewsletterPending: true,
      }

      break
    }
    case 'ADD_TO_NEWSLETTER_FULFILLED': {
      state = {
        ...state,
        addToNewsletterPending: false,
      }

      if (action.payload && action.payload.message) {
        swal('SUCCESS', action.payload.message, 'success')
      } else {
        swal('SUCCESS', 'You‘ve been successfully added to our mailing list!', 'success')
      }

      break
    }
    case 'ADD_TO_NEWSLETTER_REJECTED': {
      state = {
        ...state,
        addToNewsletterPending: false,
      }
      swal('ERROR', 'Something went wrong. Please try again later!', 'error')

      break
    }
    case 'SIGN_UP_PENDING':
    case 'LOGIN_PENDING': {
      state = {
        ...state,
        loginFetching: true,
        signupFetching: true,
      }

      break
    }
    case 'SIGN_UP_FULFILLED':
    case 'TRY_LOGIN_FULFILLED':
    case 'LOGIN_FULFILLED': {
      const user = RequestService.setUser(action.payload)
      state = {
        ...state,
        loginFetching: false,
        user: user,
      }

      break
    }
    case 'SIGN_UP_REJECTED':
    case 'LOGIN_REJECTED': {
      state = {
        ...state,
        loginFetching: false,
        signupFetching: false,
      }

      const err = action.payload

      if (err.data && err.data.error) {
        swal('ERROR', err.data.error, 'error')
      } else {
        swal('ERROR', 'Something went wrong. Please try again later!', 'error')
      }

      break
    }
    case 'LOGOUT': {
      RequestService.setUser(null)
      state = {
        ...state,
        user: null,
        userData: null,
      }

      break
    }
    case 'SEND_FORGOT_PASSWORD_PENDING': {
      state = {
        ...state,
        sendForgotPasswordFetching: true,
      }

      break
    }
    case 'SEND_FORGOT_PASSWORD_FULFILLED': {
      state = {
        ...state,
        sendForgotPasswordFetching: false,
      }

      swal('EMAIL SENT', 'Follow the instructions in the email to finish resetting your password.', 'success')

      break
    }
    case 'SEND_FORGOT_PASSWORD_REJECTED': {
      state = {
        ...state,
        sendForgotPasswordFetching: false,
      }

      swal('ERROR', 'Something went wrong. Please try again later!', 'error')

      break
    }
    case 'GET_ROOMMATES_PENDING': {
      state = {
        ...state,
        roommatesFetching: true,
      }

      break
    }
    case 'GET_ROOMMATES_FULFILLED': {
      state = {
        ...state,
        roommatesFetching: false,
        roommates: action.payload,
      }

      break
    }
    case 'GET_ROOMMATES_REJECTED': {
      state = {
        ...state,
        roommatesFetching: false,
      }

      break
    }
    case 'GET_ROOMMATE_PENDING': {
      state = {
        ...state,
        roommateFetching: true,
      }

      break
    }
    case 'GET_ROOMMATE_FULFILLED': {
      state = {
        ...state,
        roommateFetching: false,
        roommate: action.payload,
      }

      break
    }
    case 'GET_ROOMMATE_REJECTED': {
      state = {
        ...state,
        roommateFetching: false,
      }

      break
    }
    case 'GET_USER_DATA_FULFILLED': {
      state = {
        ...state,
        userData: action.payload,
      }
      break
    }
    case 'UPLOAD_USER_IMAGE_PENDING': {
      state = {
        ...state,
        imageUploadPending: true,
      }
      break
    }
    case 'UPLOAD_USER_IMAGE_FULFILLED': {
      state = {
        ...state,
        imageUploadPending: false,
        userData: {
          ...state.userData,
          picture: action.payload,
        }
      }
      break
    }
    case 'UPDATE_USER_PENDING': {
      state = {
        ...state,
        updateUserPending: true,
      }
      break
    }
    case 'UPDATE_USER_FULFILLED': {
      state = {
        ...state,
        updateUserPending: false,
        userData: action.payload,
      }

      break
    }
    case 'UPDATE_USER_REJECTED': {
      state = {
        ...state,
        updateUserPending: false,
      }
      break
    }
    case 'CHANGE_PASSWORD_PENDING': {
      state = {
        ...state,
        changePasswordPending: true,
      }
      break
    }
    case 'CHANGE_PASSWORD_FULFILLED': {
      state = {
        ...state,
        changePasswordPending: false,
      }
      break
    }
    case 'CHANGE_PASSWORD_REJECTED': {
      state = {
        ...state,
        changePasswordPending: false,
      }
      break
    }
    case 'SET_NEW_PASSWORD_PENDING': {
      state = {
        ...state,
        setNewPasswordPending: true,
      }
      break
    }
    case 'SET_NEW_PASSWORD_FULFILLED': {
      state = {
        ...state,
        setNewPasswordPending: false,
      }
      break
    }
    case 'SET_NEW_PASSWORD_REJECTED': {
      state = {
        ...state,
        setNewPasswordPending: false,
        setNewPasswordError: true,
      }
      break
    }
    case 'INITIAL_SETUP_PENDING': {
      state = {
        ...state,
        initialSetupPending: true,
      }
      break
    }
    case 'INITIAL_SETUP_FULFILLED': {
      state = {
        ...state,
        initialSetupPending: false,
      }
      break
    }
    case 'INITIAL_SETUP_REJECTED': {
      state = {
        ...state,
        initialSetupPending: false,
      }
      swal('ERROR', 'Something went wrong. Please try again.', 'error')
      break
    }
    default: {
      break
    }
  }

  return state
}
