/* global swal */

const defaultState = {
  listings: [],
  listingsFetching: false,
  totalListings: null,
  itemsPerPage: 18,
  filters: {
    page: 1,
    filter_by: {
      address: undefined,
      amenities: [],
      amenities_slug: [],
      bathrooms : [],
      bathroom: undefined,
      bbox: {},
      bedrooms: [],
      bedroom: undefined,
      exclusive: undefined,
      neighbourhood: [],
      listed_by: undefined,
      pet_policy: undefined,
      polygon: [],
      price: {},
      street: undefined,
      subway_lines: [],
    },
    sort_by: null,
  },
  similarListings: [],
  moreFilters: null,
  moreFiltersVisible: false,
  neighborhoodsFiltersVisible: false,
  homepageListings: {},
  listing: null,
  listingFetching: false,
  myListings: [],
  myListingsFetching: false,
  favoriteListings: [],
  favoriteListingsFetching: false,
  saveListingPending: false,
  getPriceStatsPending: false,
  priceStats: {},
  getCountPending: false,
  filtersCount: 0,
  roomsPreviewListings: [],
  roomsPreviewListingsPending: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_RENTAL_PENDING': {
      state = {
        ...state,
        listingFetching: true,
        listing: null,
      }
      break
    }
    case 'GET_RENTAL_FULFILLED': {
      state = {
        ...state,
        listing: action.payload,
        listingFetching: false,
      }
      break
    }
    case 'GET_RENTAL_REJECTED': {
      state = {
        ...state,
        listingFetching: false,
      }
      break
    }
    case 'GET_ROOMS_PREVIEW_LISTINGS_PENDING': {
      state = {
        ...state,
        roomsPreviewListingsPending: true,
      }
      break
    }
    case 'GET_ROOMS_PREVIEW_LISTINGS_FULFILLED': {
      state = {
        ...state,
        roomsPreviewListings: action.payload.lease_details,
        roomsPreviewListingsPending: false,
      }
      break
    }
    case 'GET_ROOMS_PREVIEW_LISTINGS_REJECTED': {
      state = {
        ...state,
        roomsPreviewListingsPending: false,
      }
      break
    }

    case 'GET_EDIT_RENTAL_PENDING': {
      state = {
        ...state,
        editListing: null,
      }
      break
    }
    case 'GET_EDIT_RENTAL_FULFILLED': {
      state = {
        ...state,
        editListing: action.payload,
      }
      break
    }
    case 'SAVE_RENTAL_PENDING': {
      state = {
        ...state,
        saveListingPending: true,
      }
      break
    }
    case 'SAVE_RENTAL_FULFILLED': {
      state = {
        ...state,
        saveListingPending: false,
      }
      break
    }
    case 'SAVE_RENTAL_REJECTED': {
      state = {
        ...state,
        saveListingPending: false,
      }

      const err = action.payload

      if (err && err.data && err.data.error) {
        swal('ERROR', err.data.error, 'error')
      } else {
        swal('ERROR', 'Something went wrong. Please try again later!', 'error')
      }
      break
    }
    case 'GET_HOMEPAGE_RENTALS_FULFILLED': {
      state = {
        ...state,
        homepageListings: {
          noFeeList: [...action.payload[0].lease_details],
          noFeeTotal: action.payload[0].total_items,
          petFriendlyList: [...action.payload[1].lease_details],
          petFriendlyTotal: action.payload[1].total_items,
          luxuryList: [...action.payload[2].lease_details],
          luxuryTotal: action.payload[2].total_items
        },
      }
      break
    }
    case 'EXTEND_RENTAL_PENDING': {
      state = {
        ...state,
        myListingsFetching: true,
        extendListingPending: true,
      }
      break
    }
    case 'EXTEND_RENTAL_FULFILLED': {
      const newExtendedListing = action.payload.lease_detail

      if (newExtendedListing) {
        let newMyListings = [...state.myListings]

        const foundIndex = newMyListings.findIndex(val => val.id === newExtendedListing.id)

        newMyListings[foundIndex] = newExtendedListing

        state = {
          ...state,
          myListings: newMyListings,
          myListingsFetching: false,
          extendListingPending: false,
        }
      }
      break
    }
    case 'EXTEND_RENTAL_REJECTED': {
      state = {
        ...state,
        extendListingPending: false,
        myListingsFetching: false,
      }
      break
    }
    case 'GET_RENTAL_PRICE_STATS_PENDING': {
      state = {
        ...state,
        getPriceStatsPending: true,
        priceStats: {},
      }
      break
    }
    case 'GET_RENTAL_PRICE_STATS_FULFILLED': {
      state = {
        ...state,
        getPriceStatsPending: false,
        priceStats: action.payload || {},
      }
      break
    }
    case 'GET_RENTAL_PRICE_STATS_REJECTED': {
      state = {
        ...state,
        getPriceStatsPending: false,
      }
      break
    }
    case 'GET_RENTAL_COUNT_PENDING': {
      state = {
        ...state,
        getCountPending: true,
        filtersCount: null,
      }
      break
    }
    case 'GET_RENTAL_COUNT_FULFILLED': {
      state = {
        ...state,
        getCountPending: false,
        filtersCount: (action.payload && action.payload.total_items) || null,
      }
      break
    }
    case 'GET_RENTAL_COUNT_REJECTED': {
      state = {
        ...state,
        getCountPending: false,
        filtersCount: null,
      }
      break
    }
    case 'GET_BROWSE_RENTALS_PENDING': {
      state = {
        ...state,
        listingsFetching: true,
      }
      if (action.meta && action.meta.filters) {
        state.filters = action.meta.filters
      }
      break
    }
    case 'GET_BROWSE_RENTALS_FULFILLED': {
      const listings = action.payload && action.payload.lease_details
      const totalListings = action.payload && action.payload.total_items
      const minPrice = action.payload && action.payload.min_price
      const avgPrice = action.payload && action.payload.avg_price
      const maxPrice = action.payload && action.payload.max_price

      state = {
        ...state,
        listings: [...listings],
        totalListings,
        minPrice,
        maxPrice,
        avgPrice,
        listingsFetching: false,
      }
      break
    }
    case 'GET_BROWSE_RENTALS_REJECTED': {
      if (!(action.payload && action.payload.isCancel)) {
        state = {
          ...state,
          listingsFetching: false,
        }
      }
      break
    }
    case 'GET_SIMILAR_RENTALS_FULFILLED': {
      state = {
        ...state,
        similarListings: [...action.payload.lease_details],
      }

      break
    }
    case 'RESET_BROWSE_RENTALS': {
      state = {
        ...state,
        listings: [],
        totalListings: null,
        filters: {...defaultState.filters},
        moreFilters: {},
        moreFiltersVisible: false,
        neighborhoodsFiltersVisible: false,
      }
      break
    }
    case 'RESET_RENTAL': {
      state = {
        ...state,
        listing: null,
        similarListings: [],
      }
      break
    }
    case 'OPEN_MORE_RENTAL_FILTERS': {
      const filterBy = state.filters.filter_by

      state = {
        ...state,
        moreFilters: {
          amenities: filterBy.amenities,
          amenities_slug: filterBy.amenities_slug,
          bathrooms : filterBy.bathrooms,
          bathroom: filterBy.bathroom,
          exclusive: filterBy.exclusive,
          pet_policy: filterBy.pet_policy,
          subway_lines: filterBy.subway_lines,
          price: {
            min: filterBy.price && filterBy.price.min,
            max: filterBy.price && filterBy.price.max,
          },
        },
        moreFiltersVisible: true,
      }

      break
    }
    case 'SET_MORE_RENTAL_FILTERS': {
      state = {
        ...state,
        moreFilters: {...action.payload},
      }
      break
    }
    case 'CLOSE_MORE_RENTAL_FILTERS': {
      state = {
        ...state,
        moreFiltersVisible: false,
        moreFilters: null,
      }
      break
    }
    case 'GET_MY_RENTALS_PENDING':
    case 'GET_MY_PRO_RENTALS_PENDING': {
      state = {
        ...state,
        myListingsFetching: true,
      }
      break
    }
    case 'GET_MY_RENTALS_REJECTED':
    case 'GET_MY_PRO_RENTALS_REJECTED': {
      state = {
        ...state,
        myListingsFetching: false,
      }
      break
    }
    case 'GET_MY_PRO_RENTALS_FULFILLED': {
      state = {
        ...state,
        myListings: [...state.myListings, ...action.payload],
        myListingsFetching: false,
      }
      break
    }
    case 'GET_MY_RENTALS_FULFILLED': {
      state = {
        ...state,
        myListings: [...action.payload],
        myListingsFetching: false,
      }
      break
    }
    case 'REMOVE_RENTAL_FULFILLED': {
      let newMyListings = [...state.myListings]

      const foundIndex = newMyListings.findIndex(listing => listing.id === action.payload.id)

      if (foundIndex !== -1) {
        newMyListings.splice(foundIndex, 1)
        newMyListings.forEach(l => {
          l.total_items = l.total_items - 1
        })
      }

      state = {
        ...state,
        myListings: newMyListings,
      }
      break
    }
    case 'GET_MY_PRO_SALES_PENDING':
    case 'RESET_MY_RENTALS': {
      state = {
        ...state,
        myListings: [],
      }
      break
    }
    case 'SET_UNPUBLISH_RENTAL': {
      let newMyListings = [...state.myListings]
      const foundIndex = newMyListings.findIndex(listing => listing.id === action.payload.id)

      if (foundIndex !== -1) {
        newMyListings[foundIndex].unpublished = action.payload.unpublished
      }

      state = {
        ...state,
        myListings: newMyListings,
      }
      break
    }
    case 'SET_UNPUBLISH_RENTAL_REJECTED': {
      swal('ERROR', 'Something went wrong while updating the listing. Please refresh the page and try again.', 'error')
      break
    }
    case 'FAVORITE_RENTAL_PENDING': {
      const listingId = action.meta.rental.id
      let newRental = {
        ...action.meta.rental,
        favorite: !action.meta.rental.favorite,
      }

      const updateNewListing = listing => listingId === listing.id ? newRental : listing

      const foundFavoriteIndex = state.favoriteListings.findIndex(listing => listing.id === listingId)
      let newFavoriteListings = [...state.favoriteListings]
      newFavoriteListings.splice(foundFavoriteIndex, 1)

      const newListings = state.listings.map(updateNewListing)
      const newMyListings = state.myListings.map(updateNewListing)
      const newListing = state.listing && state.listing.id === listingId ? newRental : state.listing

      state = {
        ...state,
        listings: newListings,
        favoriteListings: newFavoriteListings,
        myListings: newMyListings,
        listing: newListing,
      }

      break
    }
    case 'FAVORITE_RENTAL_REJECTED': {
      swal('ERROR', 'Something went wrong while updating the favorite listings. Please refresh the page and try again.', 'error')
      break
    }
    case 'GET_FAVORITE_RENTALS_PENDING': {
      state = {
        ...state,
        favoriteListingsFetching: true,
      }
      break
    }
    case 'GET_FAVORITE_RENTALS_FULFILLED': {
      state = {
        ...state,
        favoriteListings: [...action.payload],
        favoriteListingsFetching: false,
      }
      break
    }
    case 'GET_FAVORITE_RENTALS_REJECTED': {
      state = {
        ...state,
        favoriteListingsFetching: false,
      }
      break
    }
    case 'RESET_FAVORITE_RENTALS': {
      state = {
        ...state,
        favoriteListings: [],
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
