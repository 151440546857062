import qs from 'qs'

import articleService from '../services/articleService'

const getFiltersFromUrl = (queryString, extraFilters) => {
  const queryFilters = qs.parse(decodeURI(queryString), {
    decoder: str => {
      const floatStr = parseFloat(str)

      if (!isNaN(floatStr) && str === floatStr) {
        str = floatStr
      } else if (str === 'true') {
        str = true
      } else if (str === 'false') {
        str = false
      }

      return str
    },
    allowDots: true,
    arrayLimit: 200,
  }) || {}

  return {
    page: queryFilters.page || 1,
    tag: queryFilters.tag || '',
    ...extraFilters,
  }
}

export const getBrowseArticles = (queryString, extraFilters) => {
  const filters = getFiltersFromUrl(queryString, extraFilters)

  return {
    type: 'GET_BROWSE_ARTICLES',
    payload: articleService.getBrowseArticles(filters),
    meta: {
      filters,
    },
  }
}

export const resetBrowseArticles = () => {
  return {
    type: 'RESET_BROWSE_ARTICLES',
  }
}

export const getArticles = () => {
  return {
    type: 'GET_ARTICLES',
    payload: articleService.getArticles(),
  }
}

export const getSimilarArticles = articleSlug => {
  return {
    type: 'GET_SIMILAR_ARTICLES',
    payload: articleService.getSimilarArticles(articleSlug),
  }
}

export const getArticle = articleSlug => {
  return {
    type: 'GET_ARTICLE',
    payload: articleService.getArticle(articleSlug),
  }
}

export const resetArticle = () => {
  return {
    type: 'GET_ARTICLE_FULFILLED',
    payload: null,
  }
}

export const getFeaturedArticles = () => {
  return {
    type: 'GET_FEATURED_ARTICLES',
    payload: articleService.getFeaturedArticles(),
  }
}

export const getArticleTag = (queryString) => {
  const filters = getFiltersFromUrl(queryString)

  return {
    type: 'GET_ARTICLE_TAG',
    payload: filters.tag ? articleService.getArticleTag(filters.tag) : null,
  }
}

export const resetArticleTag = slug => {
  return {
    type: 'GET_ARTICLE_TAG',
    payload: null,
  }
}
