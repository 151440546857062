const defaultState = {
  loginModalVisible: false,
  signupModalVisible: false,
  forgotPasswordModalVisible: false,
  contactModalVisible: false,
  listingPhotoCarouselModalVisible: false,
  listingShareModalVisible: false,
  creditCardModalVisible: false,
  requestViewingModalVisible: false,
  viewingReviewModalVisible: false,
  buildingReviewModalVisible: false,
}

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case 'RESET_MODALS': {
      state = {
        ...defaultState,
      }
      break
    }
    case 'SET_LOGIN_MODAL_VISIBILITY': {
      state = {
        ...state,
        loginModalVisible: action.payload,
      }
      break
    }
    case 'SET_SIGNUP_MODAL_VISIBILITY': {
      state = {
        ...state,
        signupModalVisible: action.payload,
      }
      break
    }
    case 'SET_CREDIT_CARD_MODAL_VISIBILITY': {
      state = {
        ...state,
        // creditCardModalVisible: action.payload,
        creditCardModalVisible: false,
      }
      break
    }
    case 'SET_REQUEST_VIEWING_MODAL_VISIBILITY': {
      state = {
        ...state,
        requestViewingModalVisible: action.payload,
      }
      break
    }
    case 'SET_VIEWING_REVIEW_MODAL_VISIBILITY': {
      state = {
        ...state,
        viewingReviewModalVisible: action.payload,
      }
      break
    }
    case 'SET_BUILDING_REVIEW_MODAL_VISIBILITY': {
      state = {
        ...state,
        buildingReviewModalVisible: action.payload,
      }
      break
    }
    case 'SET_FORGOT_PASSWORD_MODAL_VISIBILITY': {
      state = {
        ...state,
        forgotPasswordModalVisible: action.payload,
      }
      break
    }
    case 'SET_CONTACT_MODAL_VISIBILITY': {
      state = {
        ...state,
        contactModalVisible: action.payload
      }
      break
    }
    case 'SET_LISTING_PHOTO_CAROUSEL_MODAL_VISIBILITY': {
      state = {
        ...state,
        listingPhotoCarouselModalVisible: action.payload,
      }
      break
    }
    case 'SET_LISTING_SHARE_MODAL_VISIBILITY': {
      state = {
        ...state,
        listingShareModalVisible: action.payload,
      }
      break
    }
    case 'ADD_CREDIT_CARD_FULFILLED': {
      state = {
        ...state,
        creditCardModalVisible: false,
      }
      break
    }
    case 'LOGIN_FULFILLED': {
      state = {
        ...state,
        loginModalVisible: false,
        signupModalVisible: false,
      }
      break
    }
    case 'TRY_LOGIN_FULFILLED':
    case 'SIGN_UP_FULFILLED': {
      state = {
        ...state,
        signupModalVisible: false,
      }
      break
    }
    case 'SEND_FORGOT_PASSWORD_FULFILLED': {
      state = {
        ...state,
        forgotPasswordModalVisible: false,
      }
      break
    }
    case 'CREATE_CONVERSATION_FULFILLED': {
      state = {
        ...state,
        contactModalVisible: false,
      }
      break
    }
    case 'CREATE_VIEWING_FULFILLED': {
      state = {
        ...state,
        requestViewingModalVisible: false,
      }
      break
    }
    case 'CREATE_VIEWING_REVIEW_FULFILLED': {
      state = {
        ...state,
        viewingReviewModalVisible: false,
      }
      break
    }
    case 'CREATE_BUILDING_REVIEW_FULFILLED': {
      state = {
        ...state,
        buildingReviewModalVisible: false,
      }
      break
    }
    case 'SET_MOBILE_MENU_VISIBILITY': {
      state = {
        ...defaultState,
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
