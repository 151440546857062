import { RequestService } from '../store'

class UserService {
  login(data) {
    return RequestService.post('/user/login', null, data)
  }

  signUp(data) {
    return RequestService.post('/user', null, data)
  }

  getRoommates() {
    return RequestService.get('/user', {
      is_roommate: true,
    })
  }

  getRoommate(userId) {
    return RequestService.get('/user/' + userId)
  }

  addToNewsletter(data) {
    return RequestService.post('/user/newsletter', null, data)
  }

  addCreditCard(data) {
    return RequestService.post('/credit_cards', null, data)
  }

  removeCreditCard(creditCardId) {
    return RequestService.delete('/credit_cards/' + creditCardId)
  }

  sendForgotPassword(data) {
    return RequestService.post('/password_reset', null, data)
  }

  getUser(userId) {
    if (RequestService.user.is_pro) {
      return RequestService.get('/pro_user/' + userId)
    }
    return RequestService.get('/user/' + userId)
  }

  getProUserSuggestions(value) {
    return RequestService.get(`/pro_user`, {
      search: value,
    })
  }

  uploadUserImage(image) {
    if (RequestService.user.is_pro) {
      return RequestService.multipart('/pro_user/change_picture', image)
    }
    return RequestService.multipart('/user/change_picture', image)
  }

  updateUser(data) {
    const route = (RequestService.user.is_pro ? '/pro_user/' : '/user/') + RequestService.user.id;
    return RequestService.put(route, null, data)
  }

  setNewPassword(data) {
    return RequestService.put('/password_reset/update', null, data)
  }

  changePassword(data) {
    if (RequestService.user) {
      if (RequestService.user.is_pro) {
        return RequestService.put('/pro_user/' + RequestService.user.id + '/change_password', null, data);
      } else {
        return RequestService.put('/user/' + RequestService.user.id + '/password_reset', null, data);
      }
    }
  }

  initialSetup(data) {
    return RequestService.put('/user/' + data.id + '/initial_setup', null, data);
  }

  favorite(rentalId, saleId) {
    return RequestService.post('/user/favorites', null, {
      listing_id: rentalId || undefined,
      unit_sale_id: saleId || undefined,
    })
  }
}

export default new UserService()
