import conversationService from '../services/conversationService'

const getConversationFunction = (dispatch, conversationId, userId) => {
  dispatch({
    type: 'GET_CONVERSATION_PENDING',
    payload: conversationId,
  })

  return conversationService.getConversation(conversationId, userId).then(response => {
    dispatch({
      type: 'GET_CONVERSATION_FULFILLED',
      payload: response,
    })
  }, err => {
    dispatch({
      type: 'GET_CONVERSATION_REJECTED',
      payload: err,
    })
  })
}

const getConversationsFunction = (dispatch, userId, getInitialConversation) => {
  dispatch({
    type: 'GET_CONVERSATIONS_PENDING',
  })

  return conversationService.getConversations(userId).then(response => {
    dispatch({
      type: 'GET_CONVERSATIONS_FULFILLED',
      payload: response,
    })

    if (getInitialConversation && response.conversations && response.conversations[0]) {
      return getConversationFunction(dispatch, response.conversations[0].id, userId)
    }
  }, err => {
    dispatch({
      type: 'GET_CONVERSATIONS_REJECTED',
      payload: err,
    })
  })
}

export const getConversations = (userId) => {
  return dispatch => {
    return getConversationsFunction(dispatch, userId, true)
  }
}

export const getConversation = (conversationId, userId) => {
  return dispatch => {
    return getConversationFunction(dispatch, conversationId, userId)
  }
}

export const sendMessage = (newMessage, conversationId, userId) => {
  return dispatch => {
    dispatch({
      type: 'SEND_MESSAGE_PENDING',
    })

    return conversationService.sendMessage({
      conversation: {
        id: conversationId,
      },
      message: {
        content: newMessage,
        sent_by: userId,
      }
    }).then(newConversation => {
      dispatch({
        type: 'SEND_MESSAGE_FULFILLED',
      })
      dispatch({
        type: 'GET_CONVERSATION_FULFILLED',
        payload: newConversation,
      })
      return getConversationsFunction(dispatch, userId)
    }, err => {
      dispatch({
        type: 'SEND_MESSAGE_REJECTED',
        payload: err,
      })
    })
  }
}

export const resetConversations = () => {
  return {
    type: 'RESET_CONVERSATIONS',
  }
}

export const resetConversation = () => {
  return {
    type: 'RESET_CONVERSATION',
  }
}

export const createConversation = data => {
  return {
    type: 'CREATE_CONVERSATION',
    payload: conversationService.createConversation(data),
  }
}

export const socketUpdateNewMessage = data => {
  return (dispatch, getState) => {
    const {
      conversation: {
        conversation,
      },
      user: {
        user,
      },
      router,
    } = getState()

    if (router.location.pathname === '/inbox' && conversation) {
      getConversationsFunction(dispatch, user.id)

      const foundConversation = data.unread_conversations.find(newConversation => newConversation.conversation_id === conversation.id)

      if (foundConversation) {
        conversationService.getConversation(conversation.id, user.id).then(response => {
          dispatch({
            type: 'GET_CONVERSATION_FULFILLED',
            payload: response,
          })
        })
      } else {
        dispatch({
          type: 'SET_UNREAD_CONVERSATIONS',
          payload: data.unread_conversations.length,
        })
      }
    } else {
      dispatch({
        type: 'SET_UNREAD_CONVERSATIONS',
        payload: data.unread_conversations.length,
      })
    }
  }
}
