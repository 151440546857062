const defaultState = {
  articles: [],
  featuredArticles: [],
  article: null,
  filters: {
    page: 1,
  },
  similarArticles: [],
  articleTag: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_BROWSE_ARTICLES_PENDING': {
      state = {
        ...state,
        articlesFetching: true,
        articlesTotalItems: null,
        filters: action.meta.filters,
      }
      break
    }
    case 'GET_BROWSE_ARTICLES_FULFILLED': {
      state = {
        ...state,
        articles: action.payload,
        articlesFetching: false,
        articlesTotalItems: action.payload && action.payload.length && action.payload[0].total_items,
      }
      break
    }
    case 'GET_BROWSE_ARTICLES_REJECTED': {
      state = {
        ...state,
        articles: [],
        articlesFetching: false,
        articlesTotalItems: null,
      }
      break
    }
    case 'RESET_BROWSE_ARTICLES': {
      state = {
        ...state,
        articles: [],
        articlesFetching: false,
        articlesTotalItems: null,
        filters: {...defaultState.filters},
      }
      break
    }
    case 'GET_SIMILAR_ARTICLES_FULFILLED': {
      state = {
        ...state,
        similarArticles: [...action.payload],
      }
      break
    }
    case 'GET_ARTICLE_FULFILLED': {
      state = {
        ...state,
        article: action.payload,
      }
      break
    }
    case 'GET_ARTICLE_TAG_FULFILLED': {
      state = {
        ...state,
        articleTag: action.payload,
      }
      break
    }
    case 'GET_FEATURED_ARTICLES_FULFILLED': {
      state = {
        ...state,
        featuredArticles: [...action.payload],
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
