/* global CLIENT */

import axios from 'axios'

import { RequestService } from '../store'

import * as constants from '../helpers/constants'

const CancelToken = axios.CancelToken
let cancel, cancelFiltersStats, cancelFiltersCount

class SaleService {
  getListing(listingId, params) {
    return RequestService.get('/unit_sales/' + listingId, params)
  }

  removeListing(listingId) {
    return RequestService.delete('/unit_sales/' + listingId, null)
  }

  sendSellContact(data) {
    return RequestService.post('/unit_sales/sell_contact', null, data)
  }

  sendRebateContact(data) {
    return RequestService.post('/unit_sales/rebate_contact', null, data)
  }

  getBrokerListings(brokerId) {
    return RequestService.post('/unit_sales/filter?total_items=3&page=1', null, {
      filter_by: {
        listed_by: brokerId,
      },
    })
  }

  getHomepageListings(filters) {
    return Promise.all([
      RequestService.post('/unit_sales/filter', {
        total_items: 3,
        page: 5,
      }, {
        filter_by: {...filters[0]},
      }),
      RequestService.post('/unit_sales/filter', {
        total_items: 3,
        page: 1,
      }, {
        filter_by: {...filters[1]},
      }),
      RequestService.post('/unit_sales/filter', {
        total_items: 3,
        page: 1,
      }, {
        filter_by: {...filters[2]},
      }),
    ])
  }

  getMyProSales(loadedListings) {
    return RequestService.get('/unit_sales', {
      total_items: 10,
      page: (loadedListings && loadedListings / 10) || 1,
    })
  }

  getFavoriteSales() {
    return RequestService.get('/unit_sales/my_favorites')
  }

  saveSale(sale) {
    return RequestService.post('/unit_sales', null, sale)
  }

  updateSale(sale) {
    return RequestService.put('/unit_sales/' + sale.id, null, sale)
  }

  cancelGetBrowseSales() {
    if (cancel) {
      cancel()
    }
  }

  getBrowseSales(filters) {
    if (cancel && __CLIENT__) {
      cancel()
    }

    let newFilters = {...filters}

    if (newFilters.filter_by.bbox && newFilters.filter_by.bbox.sw) {
      const height = Math.abs(newFilters.filter_by.bbox.sw[0] - newFilters.filter_by.bbox.ne[0])
      const width = Math.abs(newFilters.filter_by.bbox.sw[1] - newFilters.filter_by.bbox.ne[1])

      newFilters.filter_by.bbox.ne[0] = parseFloat(newFilters.filter_by.bbox.ne[0]) - parseFloat(height / 7)
      newFilters.filter_by.bbox.sw[0] = parseFloat(newFilters.filter_by.bbox.sw[0]) + parseFloat(height / 8)

      newFilters.filter_by.bbox.ne[1] = parseFloat(newFilters.filter_by.bbox.ne[1]) - parseFloat(width / 5)
      newFilters.filter_by.bbox.sw[1] = parseFloat(newFilters.filter_by.bbox.sw[1]) + parseFloat(width / 5)
    }

    return RequestService.post('/unit_sales/filter', {
        total_items: constants.browseListingsPerPage,
        page: (filters && filters.page) || 1,
      }, newFilters, {
        cancelToken: new CancelToken(function executor (c) {
          cancel = c;
        }),
      },
    )
  }

  getSalePriceStats(filters) {
    if (cancelFiltersStats) {
      cancelFiltersStats()
    }

    let newFilters = {...filters}

    return RequestService.post('/unit_sales/price_stats', {
      }, newFilters, {
        cancelToken: new CancelToken(function executor (c) {
          cancelFiltersStats = c
        }),
      },
    )
  }

  getSaleCount(filters) {
    if (cancelFiltersCount) {
      cancelFiltersCount()
    }

    let newFilters = {...filters}

    return RequestService.post('/unit_sales/count', {
      }, newFilters, {
        cancelToken: new CancelToken(function executor (c) {
          cancelFiltersCount = c
        }),
      },
    )
  }

  getSimilarSales(saleId) {
    return RequestService.get('/unit_sales/' + saleId + '/similar')
  }

  extendListing(data) {
    return RequestService.put('/unit_sales/' + data.id + '/extend', null, data)
  }
}

export default new SaleService()
