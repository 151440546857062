import { RequestService } from '../store'

class SeoContentService {
  getSeoContent(listingType, areaTerm, filterTerm) {
    return RequestService.get('/seo_contents', {
        url_listing_type: listingType || '',
        url_area_term: areaTerm || '',
        url_filter_term: filterTerm || '',
    })
  }
}

export default new SeoContentService()
