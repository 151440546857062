/* global __CLIENT__, FB */

// import 'react-dates/initialize'
// import $ from 'jquery'

global.swal = () => { console.log('>>> YOU ARE TRYING TO USE SWAL ON THE SERVER') }

if (__CLIENT__) {
  // global.stripe = window.Stripe(__ENV__ === 'production' && window.location.href.indexOf('dev.propertyclub') === -1 ? 'pk_live_Rwm3DqntUdoICiOUKzPHxqsi' : 'pk_test_SH4X8mbZkucqXYlK1SAlxs2L')
  global.swal = require('sweetalert2')
  /* Facebook API init */
  window.fbAsyncInit = function () {
    FB.init({
      appId      : '231907594033019',
      xfbml      : true,
      version    : 'v2.12'
    });
  };
  (function (d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

  /* Twitter API init*/
  window.twttr = (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function (f) {
      t._e.push(f);
    };

    return t;
  }(document, "script", "twitter-wjs"))


  // const ActionCable = require('actioncable')

  // const cableHost = __ENV__ === 'production' ? 'propertyclub.nyc' : 'localhost:3020'

  // global.cable = ActionCable.createConsumer('wss://' + cableHost + '/cable')

  const removeHover = require('remove-hover')

  // $('document').ready(() => {
  //   removeHover()
  // })

  // if (__ENV__ === 'production') {
  //   // eslint-disable-next-line
  //   window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])}
  //   heap.load('2185741264')
  // }
}
