/* global FB */

import moment from 'moment'
import union from '@turf/union'
import find from 'lodash/find'
import qs from 'qs'

export const listingPlaceholderImage = '/res/image-placeholder.png'

export const getFriendlyUrl = val => {
  return val.toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g, '')
}

export const isEmailValid = val => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(val)
}

export const getMansionTax = val => {
  if (val > 999999 && val <= 1999999) {
    return parseInt(val * 0.01, 10)
  } else if (val > 1999999 && val <= 2999999) {
    return parseInt(val * 0.0125, 10)
  } else if (val > 2999999 && val <= 4999999) {
    return parseInt(val * 0.015, 10)
  } else if (val > 4999999 && val <= 9999999) {
    return parseInt(val * 0.0225, 10)
  } else if (val > 9999999 && val <= 14999999) {
    return parseInt(val * 0.0325, 10)
  } else if (val > 14999999 && val <= 19999999) {
    return parseInt(val * 0.035, 10)
  } else if (val > 19999999 && val <= 24999999) {
    return parseInt(val * 0.0375, 10)
  } else if (val > 24999999) {
    return parseInt(val * 0.039, 10)
  } else {
    return 0
  }
}

export const calculateRebate = val => {
  if (val > 999999) {
    return parseInt(val * 0.02, 10)
  } else if (val > 300000) {
    return parseInt(val * 0.03 - 10000, 10)
  } else {
    return 0
  }
}

export const toSlug = (val, type) => {
  let formattedVal = val ? val.trim().toLowerCase().replace(/['‘]+/, '') : ''

  if (type === 'alphanumeric') {
    formattedVal = formattedVal.replace(/[\W_]+/g," ").trim()
  }

  return formattedVal ? formattedVal.split(/[\s]+/).join('-') : ''
}

export const filterByString = (val, filter) => {
  return val.toLowerCase().indexOf(filter.toLowerCase()) !== -1
}
const numberWithCommasFn = number => {
  if (!number) return 0
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const numberWithCommas = numberWithCommasFn

export const prettyDate = (date, readFormat) => {
  return date ? moment(date, readFormat).format('MMM Do YYYY') : 'Not defined'
}

export const prettyDateTime = date => {
  return date ? moment(date).format('dddd, MMM Do - h:mm:ss') : 'Not defined'
}

export const prettyShortDateTime = date => {
  return date ? moment(date).format('MMM Do YYYY - H:mm') : 'Not defined'
}

export const customDateFormat = (date, format) => {
  return date ? moment(date).format(format) : 'Not defined'
}

export const isFutureDate = date => {
  return moment.utc().isBefore(moment.utc(date))
}

const numberToWordsFunction = num => {
  var ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
              'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
              'seventeen', 'eighteen', 'nineteen'];
  var tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty',
              'ninety'];

  var numString = num.toString();

  if (num < 0) throw new Error('Negative numbers are not supported.');

  if (num === 0) return 'zero';

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    return tens[numString[0]] + ' ' + ones[numString[1]];
  }

  //100 and more
  if (numString.length === 3) {
    if (numString[1] === '0' && numString[2] === '0')
      return ones[numString[0]] + ' hundred';
    else
      return ones[numString[0]] + ' hundred and ' + numberToWords(+(numString[1] + numString[2]));
  }

  if (numString.length === 4) {
    var end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[numString[0]] + ' thousand';
    if (end < 100) return ones[numString[0]] + ' thousand and ' + numberToWords(end);
    return ones[numString[0]] + ' thousand ' + numberToWords(end);
  }
}
export const numberToWords = numberToWordsFunction

const isRental = listing => {
  return listing.lease && listing.lease.pretty_title
}

const buildShareUrl = listing => {
  if (isRental(listing)) {
    return encodeURI(window.location.origin + '/rental/' + listing.id + '/' + listing.lease.pretty_title)
  } else {
    return encodeURI(window.location.origin + '/sale/' + listing.id + '/' + listing.pretty_title)
  }
}

export const facebookShareListing = listing => {
  FB.ui({
    method: 'share',
    href: buildShareUrl(listing),
  })
}

export const twitterShareListing = listing => {
  let width  = 575,
      height = 400,
      left   = (window.innerWidth  - width)  / 2,
      top    = (window.innerHeight - height) / 2,
      url    = 'http://twitter.com/share?url=' + buildShareUrl(listing),
      opts   = 'status=1' +
               ',width='  + width  +
               ',height=' + height +
               ',top='    + top    +
               ',left='   + left

  window.open(url, 'twitter', opts)
}

export const linkedinShareListing = listing => {
  let url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + buildShareUrl(listing)
  if (isRental(listing)) {
    url += '&title=' + encodeURI(listing.description.title)
    url += '&summary=' + encodeURI(listing.description.content.slice(0, 250) + (listing.description.content.length > 250 ? '...' : 0))
  } else {
    url += '&title=' + encodeURI(listing.title)
    url += '&summary=' + encodeURI(listing.description.slice(0, 250) + (listing.description.length > 250 ? '...' : 0))
  }
  url += '&source=propertyclub.nyc'
  let width  = 575,
      height = 400,
      left   = (window.innerWidth  - width)  / 2,
      top    = (window.innerHeight - height) / 2,
      opts   = 'status=1' +
           ',width='  + width  +
           ',height=' + height +
           ',top='    + top    +
           ',left='   + left

  window.open(url, 'linkedin', opts)
}

export const emailShareListing = listing => {
  const title = isRental(listing) ? listing.description.title : listing.title

  // eslint-disable-next-line
  window.open("mailto:" + '' + "?subject=" + title + " from PropertyClub" + "&body=" + buildShareUrl(listing), "_self")
}

const buildShareBuildingUrl = listing => {
  return encodeURI(window.location.origin + '/building/' + listing.slug)
}

export const facebookShareBuilding = listing => {
  FB.ui({
    method: 'share',
    href: buildShareBuildingUrl(listing),
  })
}

export const twitterShareBuilding = listing => {
  let width  = 575,
      height = 400,
      left   = (window.innerWidth  - width)  / 2,
      top    = (window.innerHeight - height) / 2,
      url    = 'http://twitter.com/share?url=' + buildShareBuildingUrl(listing),
      opts   = 'status=1' +
               ',width='  + width  +
               ',height=' + height +
               ',top='    + top    +
               ',left='   + left

  window.open(url, 'twitter', opts)
}

export const linkedinShareBuilding = listing => {
  let url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + buildShareBuildingUrl(listing)
  url += '&title=' + encodeURI(listing.name)
  url += '&summary=' + encodeURI(listing.description.slice(0, 250) + (listing.description.length > 250 ? '...' : 0))
  url += '&source=propertyclub.nyc'
  let width  = 575,
      height = 400,
      left   = (window.innerWidth  - width)  / 2,
      top    = (window.innerHeight - height) / 2,
      opts   = 'status=1' +
           ',width='  + width  +
           ',height=' + height +
           ',top='    + top    +
           ',left='   + left

  window.open(url, 'linkedin', opts)
}

export const emailShareBuilding = listing => {
  const title = listing.name

  // eslint-disable-next-line
  window.open("mailto:" + '' + "?subject=" + title + " from PropertyClub" + "&body=" + buildShareBuildingUrl(listing), "_self")
}

const buildShareArticleUrl = article => {
  return encodeURI(window.location.origin + '/article/' + article.slug)
}

export const facebookShareArticle = article => {
  FB.ui({
    method: 'share',
    href: buildShareArticleUrl(article),
  })
}

export const twitterShareArticle = article => {
  let width  = 575,
      height = 400,
      left   = (window.innerWidth  - width)  / 2,
      top    = (window.innerHeight - height) / 2,
      url    = 'http://twitter.com/share?url=' + buildShareArticleUrl(article),
      opts   = 'status=1' +
               ',width='  + width  +
               ',height=' + height +
               ',top='    + top    +
               ',left='   + left

  window.open(url, 'twitter', opts)
}

export const linkedinShareArticle = article => {
  let url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + buildShareArticleUrl(article)
  url += '&title=' + encodeURI(article.title)
  url += '&summary=' + encodeURI(article.preview_text.slice(0, 250) + (article.preview_text.length > 250 ? '...' : ''))
  url += '&source=propertyclub.nyc'
  let width  = 575,
      height = 400,
      left   = (window.innerWidth  - width)  / 2,
      top    = (window.innerHeight - height) / 2,
      opts   = 'status=1' +
           ',width='  + width  +
           ',height=' + height +
           ',top='    + top    +
           ',left='   + left

  window.open(url, 'linkedin', opts)
}

export const emailShareArticle = article => {
  // eslint-disable-next-line
  window.open("mailto:" + '' + "?subject=" + article.title + " by " + article.author + "&body=" + buildShareArticleUrl(article), "_self")
}

export const abbrNum = (number, decPlaces) => {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces)

  // Enumerate number abbreviations
  const abbrev = ['k', 'm', 'b', 't']

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {

    // Convert array index to "1000", "1000000", etc
    let size = Math.pow(10, (i + 1) * 3)

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round(number * decPlaces / size) / decPlaces

      // Handle special case where we round up to the next abbreviation
      if ((number === 1000) && (i < abbrev.length - 1)) {
        number = 1
        i++
      }

      // Add the letter for the abbreviation
      number += abbrev[i]

      // We are done... stop
      break
    }
  }

  return number
}

export const getNeighbourhoodPolygons = (neighbourhoods) => {
  let polygons = {
    type: 'FeatureCollection',
    features: []
  }

  let neighbourhoodsCopy = JSON.parse(JSON.stringify(neighbourhoods))

  neighbourhoodsCopy.forEach(sublocality => {
    sublocality.regions.forEach(region => {
      region.neighbourhoods.forEach(neighbourhood => {
        polygons.features.push({
          type: 'Feature',
          properties: {
            sublocality: sublocality.sublocality,
            id: neighbourhood.id,
            name: neighbourhood.name,
            selected: false
          },
          geometry: {
            type: 'Polygon',
            coordinates: [neighbourhood.coordinates]
          }
        })
      })
    })
  })

  return polygons
}

export const unifyPolygons = featureCollection => {
  let unionTemp

  for (let i = 0; i < featureCollection.length; ++i) {
    if (!unionTemp) {
      unionTemp = featureCollection[i]
    } else {
      unionTemp = union(unionTemp, featureCollection[i])
    }
  }

  return unionTemp
}

const getNeighbourhoodsFromTerm = (term, neighbourhoods) => {
  let foundNeighbourhoods = []
  let found = false

  if (!(neighbourhoods && neighbourhoods.length)) {
    return []
  }

  term = term.toLowerCase()

  neighbourhoods.forEach(sublocality => {
    if (sublocality.sublocality.toLowerCase() === term) {
      found = sublocality.sublocality
    }

    sublocality.regions.forEach(region => {
      if (region.name.toLowerCase() === term) {
        found = region.name
      }

      region.neighbourhoods.forEach(neighbourhood => {
        if (neighbourhood.name.replace(/['‘]+/, '').replace(/[^A-Za-z0-9\s!?]/g,' ').toLowerCase() === term || found) {
          foundNeighbourhoods.push(neighbourhood.name)
        }
      })

      if (found === region.name) {
        found = false
      }
    })

    if (found === sublocality.sublocality) {
      found = false
    }
  })

  return foundNeighbourhoods
}

export const getFiltersFromTerm = (areaTerm, filterTerm, neighbourhoods) => {
  let filters = {
    neighbourhood: [],
    zone_slug: null,
    amenities_slug: [],
    pet_policy: null,
    address: null,
    penthouse: null,
    building_types: [],
    bedrooms: [],
    bedroom: null,
  }

  const ZONES_LIST = ['central-park', 'times-square', 'prospect-park', 'union-square']

  ZONES_LIST.forEach(zone => {
    if (areaTerm === zone) {
      filters.zone_slug = zone
    }
  })

  if (areaTerm === 'fifth-avenue') {
    filters.address = 'Fifth Avenue'
  } else if (areaTerm === 'broadway') {
    filters.address = 'Broadway'
  } else if (areaTerm === 'village') {
    filters.neighbourhood = ['West Village', 'East Village', 'Greenwich Village']
  } else {
    const neighborhoodName = areaTerm.split('-').join(' ')
    filters.neighbourhood = getNeighbourhoodsFromTerm(neighborhoodName, neighbourhoods)
  }

  if (filterTerm) {
    if (filterTerm === 'no-fee') {
      filters.amenities_slug = ['no-fee']
    } else if (filterTerm === 'luxury') {
      filters.amenities_slug = ['doorman', 'elevator', 'washer-dryer']
    } else if (filterTerm === 'pet-friendly') {
      filters.pet_policy = 3
    } else if (filterTerm === 'loft') {
      filters.amenities_slug = ['loft']
    } else if (filterTerm === 'penthouses') {
      filters.penthouse = true
    } else if (filterTerm === 'furnished') {
      filters.amenities_slug = ['furnished']
    } else if (filterTerm === 'condos') {
      filters.building_types = ['Condo']
    } else if (filterTerm === 'coops') {
      filters.building_types = ['Co-op']
    } else if (filterTerm === 'townhouses-and-brownstones') {
      filters.building_types = ['Townhouse']
    } else if (filterTerm === 'studio') {
      filters.bedrooms = [0]
    } else if (filterTerm === '1-bedroom-apartments') {
      filters.bedrooms = [1]
    } else if (filterTerm === '2-bedroom-apartments') {
      filters.bedrooms = [2]
    } else if (filterTerm === '3-bedroom-apartments') {
      filters.bedrooms = [3]
    } else if (filterTerm === '4-bedroom-apartments') {
      filters.bedroom = 4
    }
  }

  // Prevent override of actual filters in browse listing actions
  let newFilters = {}

  if (filters.neighbourhood.length) {
    newFilters.neighbourhood = filters.neighbourhood
  }

  if (filters.zone_slug) {
    newFilters.zone_slug = filters.zone_slug
  }

  if (filters.amenities_slug.length) {
    newFilters.amenities_slug = filters.amenities_slug
  }

  if (filters.pet_policy) {
    newFilters.pet_policy = filters.pet_policy
  }

  if (filters.address) {
    newFilters.address = filters.address
  }

  if (filters.penthouse) {
    newFilters.penthouse = filters.penthouse
  }

  if (filters.building_types && filters.building_types.length) {
    newFilters.building_types = filters.building_types
  }

  if (filters.bedrooms && filters.bedrooms.length) {
    newFilters.bedrooms = filters.bedrooms
  }

  if (filters.bedroom) {
    newFilters.bedroom = filters.bedroom
  }

  return newFilters
}

export const resolveSeoContentVariables = (seoContent, minPrice, maxPrice, avgPrice, totalListings) => {
  const formattedMinPrice = '$' + numberWithCommasFn(minPrice)
  const formattedMaxPrice = '$' + numberWithCommasFn(maxPrice)
  const formattedAvgPrice = '$' + numberWithCommasFn(avgPrice)
  const formattedTotalListings = numberWithCommasFn(totalListings)

  return {
    ...seoContent,
    seo_h1: seoContent.seo_h1 && seoContent.seo_h1
      .replace(/%min_price%/gi, formattedMinPrice)
      .replace(/%max_price%/gi, formattedMaxPrice)
      .replace(/%avg_price%/gi, formattedAvgPrice)
      .replace(/%total_results%/gi, formattedTotalListings),
    seo_pagination_description: seoContent.seo_pagination_description && seoContent.seo_pagination_description
      .replace(/%min_price%/gi, formattedMinPrice)
      .replace(/%max_price%/gi, formattedMaxPrice)
      .replace(/%avg_price%/gi, formattedAvgPrice)
      .replace(/%total_results%/gi, formattedTotalListings),
    seo_page_title: seoContent.seo_page_title && seoContent.seo_page_title
      .replace(/%min_price%/gi, formattedMinPrice)
      .replace(/%max_price%/gi, formattedMaxPrice)
      .replace(/%avg_price%/gi, formattedAvgPrice)
      .replace(/%total_results%/gi, formattedTotalListings),
    seo_meta_description: seoContent.seo_meta_description && seoContent.seo_meta_description
      .replace(/%min_price%/gi, formattedMinPrice)
      .replace(/%max_price%/gi, formattedMaxPrice)
      .replace(/%avg_price%/gi, formattedAvgPrice)
      .replace(/%total_results%/gi, formattedTotalListings),
    seo_anchor_text: seoContent.seo_anchor_text && seoContent.seo_anchor_text
      .replace(/%min_price%/gi, formattedMinPrice)
      .replace(/%max_price%/gi, formattedMaxPrice)
      .replace(/%avg_price%/gi, formattedAvgPrice)
      .replace(/%total_results%/gi, formattedTotalListings),
    seo_term_description: seoContent.seo_term_description && seoContent.seo_term_description
      .replace(/%min_price%/gi, formattedMinPrice)
      .replace(/%max_price%/gi, formattedMaxPrice)
      .replace(/%avg_price%/gi, formattedAvgPrice)
      .replace(/%total_results%/gi, formattedTotalListings),
  }
}

export const toQuery = filters => {
  return qs.stringify(filters, {
    allowDots: true,
  })
}

export const getDaysOnMarket = listedOn => {
  var duration = moment.duration(moment.utc().diff(moment.utc(listedOn)))

  return Math.ceil(duration.asDays())
}

export const queryAddressComponents = (components, path) => {
  let ret = null

  find(components, value => {
    if (value.types[0] === path) {
      ret = value.long_name
      return true
    }
  })

  return ret
}

export const getPetPolicyFromLabel = pet => {
  if (pet === 'Dog') {
    return 1
  } else if (pet === 'Cat') {
    return 2
  } else if (pet === 'Dog & Cat') {
    return 3
  } else {
    return 0
  }
}

export const getPetPolicyFromIndex = index => {
  return [
    null,
    'Dog',
    'Cat',
    'Dog & Cat',
  ][index]
}

export const isWebglSupported = () => {
  try {
    var canvas = document.createElement('canvas')
    return !!window.WebGLRenderingContext &&
      (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
    } catch(e) {
     return false
  }
}

export const getPriceCurvature = (val, min, max, strength) => {
  val /= max
  return (max-min) * Math.pow(val, strength) + min
}

export const getReversePriceCurvature = (val, min, max, strength) => {
  if (val <= min) {
    return min
  } else if (val > max) {
    return max
  }

	return Math.pow((val  - min) * Math.pow(max, strength) / (max - min), 1 / strength)
}
