import { RequestService } from '../store'

class ConversationsService {
  getConversations(loggedUserId) {
    return RequestService.get('/conversation/' + loggedUserId)
  }

  getConversation(conversationId, loggedUserId) {
    return RequestService.get('/conversation/' + conversationId + '/show_messages', { user_id: loggedUserId })
  }

  sendMessage(data) {
    return RequestService.post('/conversation', null, data)
  }

  createConversation(data) {
    return RequestService.post('/conversation', null, data)
  }
}

export default new ConversationsService()
