/* global swal */

const defaultState = {
  viewings: [],
  getViewingsPending: false,
  createViewingsPending: false,
  createViewingReviewPending: false,
  currentViewingReview: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_MY_VIEWINGS_PENDING': {
      state = {
        ...state,
        getViewingsPending: true,
      }
      break
    }
    case 'GET_MY_VIEWINGS_FULFILLED': {
      state = {
        ...state,
        getViewingsPending: false,
        viewings: [...action.payload],
      }
      break
    }
    case 'GET_MY_VIEWINGS_REJECTED': {
      state = {
        ...state,
        getViewingsPending: false,
      }
      break
    }
    case 'SET_CURRENT_VIEWING_REVIEW': {
      state = {
        ...state,
        currentViewingReview: action.payload,
      }
      break
    }
    case 'CREATE_VIEWING_PENDING': {
      state = {
        ...state,
        createViewingsPending: true,
      }
      break
    }
    case 'CREATE_VIEWING_FULFILLED': {
      state = {
        ...state,
        createViewingsPending: false,
      }
      swal('SUCCESS', 'You’ll get notified when the broker will confirm your viewing request.', 'success')
      break
    }
    case 'CREATE_VIEWING_REJECTED': {
      state = {
        ...state,
        createViewingsPending: false,
      }
      const err = action.payload

      if (err.data && err.data.error) {
        swal('ERROR', err.data.error, 'error')
      } else {
        swal('ERROR', 'Something went wrong. Please try again later!', 'error')
      }
      break
    }
    case 'CREATE_VIEWING_REVIEW_REVIEW_PENDING': {
      state = {
        ...state,
        createViewingReviewPending: true,
      }
      break
    }
    case 'CREATE_VIEWING_REVIEW_FULFILLED': {
      state = {
        ...state,
        createViewingReviewPending: false,
      }
      break
    }
    case 'CREATE_VIEWING_REVIEW_REJECTED': {
      state = {
        ...state,
        createViewingReviewPending: false,
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
