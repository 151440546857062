import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

@connect(state => ({
  pageStatus: state.app.pageStatus,
}))
export default class ErrorPage extends React.Component {
  render() {
    const {
      pageStatus,
    } = this.props

    console.log('PROPS PAGE STATUS', pageStatus)

    let pageTitle = '404 - Page not found'

    if (pageStatus === 410) {
      pageTitle = '410 - Gone'
    }

    return (
      <div className="error-page">
        <div className="container">
          <svg className="hero-image" width="257px" height="297px" viewBox="0 0 257 297" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                <stop stop-color="#8FA1B0" offset="0%"></stop>
                <stop stop-color="#5F7083" offset="100%"></stop>
              </linearGradient>
              <linearGradient x1="151.692914%" y1="-16.0254632%" x2="26.7684537%" y2="56.9678237%" id="linearGradient-2">
                <stop stop-color="#A7CCFF" offset="0%"></stop>
                <stop stop-color="#6E9DFF" offset="100%"></stop>
              </linearGradient>
              <path d="M1.28626224,74.4136565 C7.31544124,60.8136494 2.19005863,52.8139002 16.1389702,33.3369656 C18.3903574,30.1933426 29.0862581,24.2319054 35.9573424,16.6769325 C42.6628438,9.30402313 45.5512752,0.294776904 47.3508007,0.404952701 C51.215686,0.641579985 61.3614117,8.19841393 64.9952893,10.4049527 C67.8830744,12.1584542 76.2992764,19.7202236 90.5366191,26.3683493 C104.773962,33.016475 113.67131,49.2341213 126.187972,74.4136565 C114.32527,71.9997546 107.301866,74.7611873 105.11776,82.6979546 C105.163298,65.7823513 103.487334,60.9093139 100.089868,68.0788424 C96.6924026,75.248371 98.3683665,105.236553 105.11776,158.043388 L12.4604272,158.043388 L26.3299939,82.6979546 C5.61472013,86.2417606 -2.73319043,83.4803279 1.28626224,74.4136565 Z" id="path-3"></path>
              <linearGradient x1="100%" y1="-21.7956195%" x2="50%" y2="50%" id="linearGradient-5">
                <stop stop-color="#5E587E" offset="0%"></stop>
                <stop stop-color="#302C48" offset="100%"></stop>
              </linearGradient>
              <linearGradient x1="65.3664106%" y1="60.1203068%" x2="9.99258674%" y2="46.977843%" id="linearGradient-6">
                <stop stop-color="#929FAA" offset="0%"></stop>
                <stop stop-color="#D4DBE0" offset="100%"></stop>
              </linearGradient>
              <linearGradient x1="65.3664106%" y1="60.1203068%" x2="9.99258674%" y2="46.977843%" id="linearGradient-7">
                <stop stop-color="#B8C6D1" offset="0%"></stop>
                <stop stop-color="#E3E7EB" offset="100%"></stop>
              </linearGradient>
              <linearGradient x1="65.3664106%" y1="60.1203068%" x2="9.99258674%" y2="46.977843%" id="linearGradient-8">
                <stop stop-color="#F8F9FA" offset="0%"></stop>
                <stop stop-color="#EFF1F3" offset="100%"></stop>
              </linearGradient>
              <linearGradient x1="93.4998803%" y1="48.3056977%" x2="0%" y2="55.7787887%" id="linearGradient-9">
                <stop stop-color="#F1F3F7" offset="0%"></stop>
                <stop stop-color="#D6DCE2" offset="100%"></stop>
              </linearGradient>
              <linearGradient x1="100%" y1="100%" x2="0%" y2="100%" id="linearGradient-10">
                <stop stop-color="#D9DFE6" offset="0%"></stop>
                <stop stop-color="#C1CAD5" offset="100%"></stop>
              </linearGradient>
            </defs>
            <g id="Mobile---Error-States" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="12_Article-Not-Found" transform="translate(-55.000000, -70.000000)">
                <g id="Artwork" transform="translate(55.000000, 68.000000)">
                  <g id="lines" opacity="0.5" transform="translate(162.000000, 23.000000) rotate(87.000000) translate(-162.000000, -23.000000) translate(140.000000, 7.000000)" fill="url(#linearGradient-1)" fillRule="nonzero">
                    <path d="M24.6147648,16.1860199 L19.5693441,6.43268658 C19.040406,5.41019327 17.7930199,5.01548156 16.7832291,5.55107329 C15.7734383,6.08666502 15.3836301,7.34974251 15.9125682,8.37223582 L20.9579889,18.1255691 C21.4869269,19.1480625 22.734313,19.5427742 23.7441038,19.0071824 C24.7538946,18.4715907 25.1437028,17.2085132 24.6147648,16.1860199 Z" id="Line"></path>
                    <path d="M11.9222521,27.3764765 L6.8768314,17.6231432 C6.34789337,16.6006499 5.10050721,16.2059382 4.09071643,16.7415299 C3.08092565,17.2771216 2.69111748,18.5401991 3.2200555,19.5626924 L8.2654762,29.3160257 C8.79441423,30.3385191 10.0418004,30.7332308 11.0515912,30.197639 C12.061382,29.6620473 12.4511901,28.3989698 11.9222521,27.3764765 Z" id="Line-Copy" transform="translate(7.571154, 23.469584) rotate(-24.000000) translate(-7.571154, -23.469584) "></path>
                    <path d="M40.0280531,12.5913912 L34.9826324,2.8380579 C34.4536944,1.81556459 33.2063082,1.42085287 32.1965174,1.95644461 C31.1867266,2.49203634 30.7969185,3.75511383 31.3258565,4.77760713 L36.3712772,14.5309405 C36.9002152,15.5534338 38.1476014,15.9481455 39.1573922,15.4125538 C40.1671829,14.876962 40.5569911,13.6138845 40.0280531,12.5913912 Z" id="Line-Copy-2" transform="translate(35.676955, 8.684499) rotate(30.000000) translate(-35.676955, -8.684499) "></path>
                  </g>
                  <g id="shirt" transform="translate(65.000000, 93.000000)">
                    <mask id="mask-4" fill="white">
                      <use xlinkHref="#path-3"></use>
                    </mask>
                    <use id="Mask" fill="url(#linearGradient-2)" xlinkHref="#path-3"></use>
                    <path d="M23.8347132,23.579138 C51.8035508,23.0057804 66.4213461,16.7984072 67.6880993,4.95701857 C68.9548525,-6.88437011 61.6459549,-10.6606221 45.7614063,-6.37173754 L23.8347132,23.579138 Z" id="Path-6" fill="url(#linearGradient-2)" opacity="0.184839221" style={{'mix-blend-mode': 'multiply'}} mask="url(#mask-4)" transform="translate(45.831576, 7.748420) rotate(10.000000) translate(-45.831576, -7.748420) "></path>
                  </g>
                  <path d="M160,155.010679 C172.733389,183.425386 183.374145,204.057379 191.482807,204.765285 C202.071736,207.051533 223.299046,192.186369 245.393711,179.735361 L247,161.414457 C235.803941,175.706049 212.981246,178.138346 202.267021,182.984296 C198.495924,181.222645 190.399568,171.912226 179.171703,133 L160,155.010679 Z" id="arm" fill="#6E9DFF" fillRule="nonzero" transform="translate(203.500000, 169.000000) rotate(-12.000000) translate(-203.500000, -169.000000) "></path>
                  <g id="head" transform="translate(90.000000, 17.000000)">
                    <path d="M32.572631,10.5938595 C46.8901947,10.5938595 57.4029745,22.3170443 56.0535817,36.7783255 L53.4417872,64.7686168 C52.0923944,79.2298981 42.7489549,90.9530828 32.572631,90.9530828 C22.396307,90.9530828 13.0528675,79.2298981 11.7034748,64.7686168 L9.09168024,36.7783255 C7.74228751,22.3170443 18.2550672,10.5938595 32.572631,10.5938595 Z" id="Rectangle-21" fill="#FF7C7C" transform="translate(32.572631, 50.773471) rotate(-4.000000) translate(-32.572631, -50.773471) "></path>
                    <path d="M51.9897596,30.9014407 C47.647155,33.5288868 42.832782,35.643732 38.2703752,35.1642038 C24.0312446,33.6676108 29.8193554,35.1730214 26.9959322,38.484384 L20.1500717,60.2269014 C20.0240197,71.0199759 24.4697599,84.0263925 30.9156695,90.4320304 C22.4062967,88.2418421 14.7386053,78.4123246 12.730563,66.1912393 L8.17262665,38.4513206 C5.81775298,24.1193951 15.4871561,11.691433 29.769843,10.6926903 C44.0525298,9.6939475 42.8048623,13.9616522 50.9338915,16.5216258 L51.9897596,30.9014407 Z" id="Combined-Shape" fill="#FF7C7C" style={{'mix-blend-mode': 'multiply'}} opacity="0.275079257"></path>
                    <path d="M12.7273448,68.243506 C9.59005558,64.8977358 7.31740943,60.9818426 5.90940632,56.4958264 C1.56288431,42.647442 -0.11590539,26.479483 0.545769952,19.5192678 C2.08585391,3.31900301 21.4273956,-4.89620187 39.2613418,3.31900301 C57.0952881,11.5342079 60.6313823,27.1721135 56.3179045,29.7942472 C52.0044266,32.4163808 23.9292305,33.486591 18.3282877,27.1721135 C12.3075873,28.9202026 10.4406063,42.6106668 12.7273448,68.243506 Z" id="Path-43" fill="url(#linearGradient-5)"></path>
                    <path d="M13.4086365,52.211244 C17.1114547,52.211244 19.6666667,50.0378627 19.6666667,46.0485437 C19.6666667,42.0592247 16.6649394,38.8252427 12.9621212,38.8252427 C9.259303,38.8252427 7.15060625,38.8576082 7.15060625,42.8469271 C7.15060625,46.8362461 9.70581825,52.211244 13.4086365,52.211244 Z" id="Oval-10" fill="#FF6A6A" transform="translate(13.408636, 45.518243) rotate(6.000000) translate(-13.408636, -45.518243) "></path>
                  </g>
                  <g id="paper" transform="translate(0.000000, 93.000000)">
                    <path d="M13,0 C31.1246425,18.3127943 47.0144453,29.3720421 60.6694083,33.1777435 C74.3243714,36.983445 98.5012353,42.9653494 133.2,51.1234568 L133.2,202.835598 C101.428958,198.372111 77.2520943,193.468464 60.6694083,188.124657 C44.0867224,182.780849 34.1969196,173.976677 31,161.712141 L13,0 Z" id="Rectangle-22-Copy-3" fill="url(#linearGradient-6)"></path>
                    <path d="M6,4 C28.7913092,21.6461276 47.0144453,32.3720421 60.6694083,36.1777435 C74.3243714,39.983445 98.5012353,44.9653494 133.2,51.1234568 L133.2,202.835598 C101.428958,198.372111 77.2520943,193.468464 60.6694083,188.124657 C44.0867224,182.780849 31.8635863,175.310011 24,165.712141 L6,4 Z" id="Rectangle-22-Copy-2" fill="url(#linearGradient-7)"></path>
                    <path d="M0,13 C26.7913092,26.6461276 47.0144453,35.3720421 60.6694083,39.1777435 C74.3243714,42.983445 98.5012353,46.6320161 133.2,50.1234568 L133.2,205.835598 C101.428958,201.372111 77.2520943,196.468464 60.6694083,191.124657 C44.0867224,185.780849 29.8635863,178.310011 18,168.712141 L0,13 Z" id="Rectangle-22" fill="url(#linearGradient-8)"></path>
                    <path d="M257,17.7901235 L243.8,173.502264 C219.236164,176.452952 200.611837,179.765852 187.927019,183.440963 C175.242202,187.116075 156.666529,194.580953 132.2,205.835598 L132.2,50.1234568 C153.779087,37.5485917 172.35476,29.2439193 187.927019,25.2094397 C203.499279,21.1749602 226.523606,18.7018547 257,17.7901235 Z M160.393646,89.0116295 L161.274866,95.7013795 L160.190327,100.462595 L159.107354,105.135702 L164.141761,109.966608 L159.518841,118.606283 L160.496366,123.404077 L158.299827,127.312289 L161.624176,132.655603 L159.488106,139.111969 L159.393646,145.331859 L167.044109,142.599258 L171.150669,142.226193 L174.825171,139.997591 L179.304923,136.56079 L184.998488,136.75803 L188.259038,137.460974 L193.984826,134.097636 L198.602158,135.667633 L203.535221,132.962352 L206.624608,131.595108 L212.921466,134.183834 L218.293092,131.530695 L225.295684,130.375621 L226.904407,125.858884 L224.437579,120.470031 L227.153922,114.255979 L225.410159,109.44326 L226.74778,103.101966 L228.969553,98.9365716 L227.287106,92.9722171 L225.603995,87.0203295 L227.910043,81.2720403 L228.576005,68.7637734 L215.984845,70.3952598 L208.418781,73.3756248 L202.217522,72.1791464 C198.327228,72.8781601 195.582996,73.4063691 193.984826,73.7637734 C193.014265,73.9808232 189.942188,76.4525341 184.768593,81.1789061 L176.460651,80.7430304 L171.728504,81.0196099 L167.045595,85.2725014 L160.393646,89.0116295 Z" id="Combined-Shape" fill="url(#linearGradient-9)"></path>
                    <path d="M155,60.7187237 C163.847142,56.0090474 174.942036,51.7541155 188.284684,47.9539279 C201.627331,44.1537404 215.303312,42.0202195 229.312626,41.5533653 L229.312626,55.1389428 C215.575525,55.2278156 202.739533,56.7588696 190.80465,59.7321048 C178.869766,62.7053399 166.934883,67.6785751 155,74.6518102 L155,60.7187237 Z" id="Path-3" fill="url(#linearGradient-10)"></path>
                    <path d="M158,161.718724 C163.5329,157.654316 172.970674,153.722018 186.313322,149.92183 C199.655969,146.121643 212.695014,144.110482 225.430456,143.888349 L224.430456,155.473926 C213.888521,155.572422 202.650111,157.108288 190.715228,160.081523 C178.780345,163.054758 167.875269,167.911521 158,174.65181 L158,161.718724 Z" id="Path-3-Copy" fill="url(#linearGradient-10)"></path>
                    <path d="M24.6778234,59.341918 C32.8265492,63.9069083 37.980533,67.5795104 52.2699687,71.8397545 C73.1455456,78.0635869 93.945651,80.6696054 114.229069,78.6256571 L113.210709,68.8314133 C94.8097397,70.6856683 75.363745,68.1554612 55.9871412,62.3785317 C42.6034028,58.3883117 29.3969867,53.2120364 22.0042271,49.0705452 L24.6778234,59.341918 Z" id="Path-4" fill="#D1D9E0" fillRule="nonzero" opacity="0.600000024" transform="translate(68.116648, 64.190706) rotate(4.000000) translate(-68.116648, -64.190706) "></path>
                    <path d="M26.9740567,80.3589418 C35.1227825,84.9239322 40.2749396,88.5616773 54.5643753,92.8219214 C75.4399522,99.0457538 94.2449295,101.791285 114.528348,99.7473369 L113.506334,89.8833791 C95.1053647,91.7376342 77.6581516,89.1376281 58.2815478,83.3606986 C44.8978094,79.3704786 32.6889573,74.1244468 25.2961978,69.9829556 L26.9740567,80.3589418 Z" id="Path-4-Copy" fill="#D1D9E0" fillRule="nonzero" opacity="0.600000024" transform="translate(69.912273, 85.198914) rotate(2.000000) translate(-69.912273, -85.198914) "></path>
                    <path d="M29.4029355,100.393827 C37.3685804,104.958818 44.6263884,109.579116 58.5947773,113.83936 C79.0013338,120.063192 95.0171835,120.791271 114.844886,118.747323 L113.800013,108.848471 C95.8124656,110.702726 81.169696,110.155066 62.2284345,104.378137 C49.1453942,100.387917 34.9665263,94.1418851 27.739863,90.0003939 L29.4029355,100.393827 Z" id="Path-4-Copy-2" fill="#D1D9E0" fillRule="nonzero" opacity="0.600000024" transform="translate(71.292374, 104.884553) rotate(1.000000) translate(-71.292374, -104.884553) "></path>
                    <path d="M31.2520822,113.268873 L36.0061577,159.86203 C47.6483636,166.850197 60.5419279,171.062854 74.6868508,172.5 C72.2772913,156.069957 72.2772913,147.862578 72.2772913,128.268949 C56.1064828,125.787784 42.4314131,120.787758 31.2520822,113.268873 Z" id="Path-5" fill="#D1D9E0" opacity="0.600000024"></path>
                  </g>
                  <g id="fingers" transform="translate(245.000000, 147.000000)" fill="#FF7C7C">
                    <rect id="Rectangle-23" transform="translate(6.000000, 4.000000) rotate(14.000000) translate(-6.000000, -4.000000) " x="0.857142857" y="1.77777778" width="10.2857143" height="4.44444444" rx="2.22222222"></rect>
                    <rect id="Rectangle-23-Copy" transform="translate(6.000000, 9.333333) rotate(14.000000) translate(-6.000000, -9.333333) " x="0.857142857" y="7.11111111" width="10.2857143" height="4.44444444" rx="2.22222222"></rect>
                    <rect id="Rectangle-23-Copy-2" transform="translate(6.000000, 14.666667) rotate(14.000000) translate(-6.000000, -14.666667) " x="0.857142857" y="12.4444444" width="10.2857143" height="4.44444444" rx="2.22222222"></rect>
                    <rect id="Rectangle-23-Copy-3" transform="translate(6.000000, 20.000000) rotate(14.000000) translate(-6.000000, -20.000000) " x="0.857142857" y="17.7777778" width="10.2857143" height="4.44444444" rx="2.22222222"></rect>
                  </g>
                  <g id="fingers" transform="translate(9.000000, 185.500000) scale(-1, -1) rotate(-10.000000) translate(-9.000000, -185.500000) translate(3.000000, 174.000000)" fill="#FF7C7C">
                    <rect id="Rectangle-23" transform="translate(6.000000, 4.000000) rotate(14.000000) translate(-6.000000, -4.000000) " x="1" y="2" width="10" height="4" rx="2"></rect>
                    <rect id="Rectangle-23-Copy" transform="translate(6.000000, 9.000000) rotate(14.000000) translate(-6.000000, -9.000000) " x="1" y="7" width="10" height="4" rx="2"></rect>
                    <rect id="Rectangle-23-Copy-2" transform="translate(6.000000, 14.000000) rotate(14.000000) translate(-6.000000, -14.000000) " x="1" y="12" width="10" height="4" rx="2"></rect>
                    <rect id="Rectangle-23-Copy-3" transform="translate(6.173648, 19.015192) rotate(14.000000) translate(-6.173648, -19.015192) " x="1.17364818" y="17.0151922" width="10" height="4" rx="2"></rect>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <h1 className="headline">{pageTitle}</h1>
          <div className="info aero">
            Looks like the page you are trying to visit doesn’t exist.
            <br />
            Please check the URL and try your luck again.
          </div>
          <Link className="btn btn-md btn-round btn-shadow pl-50 pr-50" to="/">Take me home</Link>
        </div>
      </div>
    )
  }
}
