import React from 'react'
import { renderRoutes, matchRoutes } from 'react-router-config'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import * as actions from '../actions'

@connect(state => ({
  user: state.user.user,
}), actions)
@autobind
export default class IsLoggedIn extends React.Component {
  componentDidMount() {
    const {
      user,
      replaceRoute,
      location,
      route,
    } = this.props

    const activeRoutes = matchRoutes(route.routes, location.pathname)
    const currentRoute = activeRoutes.length && activeRoutes[0].route

    if (!user && currentRoute.isLoggedIn) {
      replaceRoute('/')
    }
  }

  componentDidUpdate() {
    const {
      user,
      replaceRoute,
      route,
      location,
    } = this.props

    const activeRoutes = matchRoutes(route.routes, location.pathname)
    const currentRoute = activeRoutes.length && activeRoutes[0].route

    if (!user && currentRoute.isLoggedIn) {
      replaceRoute('/')
    }
  }

  render() {
    const {
      route,
      user,
      location,
    } = this.props

    const activeRoutes = matchRoutes(route.routes, location.pathname)
    const currentRoute = activeRoutes.length && activeRoutes[0].route

    if (currentRoute.isLoggedIn && !user) {
      return null
    }

    return renderRoutes(route.routes)
  }
}
