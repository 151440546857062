const defaultState = {
  author: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_AUTHOR_FULFILLED': {
      state = {
        ...state,
        author: action.payload,
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
