/* global swal */

import { GOOGLE_API_KEY } from '../helpers/constants'

const defaultState = {
  listings: [],
  listingsFetching: false,
  totalListings: null,
  itemsPerPage: 18,
  filters: {
    page: 1,
    filter_by: {
      address: undefined,
      amenities: [],
      amenities_slug: [],
      bathrooms : [],
      bathroom: undefined,
      bbox: {},
      bedrooms: [],
      bedroom: undefined,
      neighbourhood: [],
      listed_by: undefined,
      pet_policy: undefined,
      polygon: [],
      price: {},
      street: undefined,
      subway_lines: [],
      open_houses: undefined,
      building_types: [],
    },
    sort_by: null,
  },
  similarListings: [],
  moreFilters: null,
  moreFiltersVisible: false,
  neighborhoodsFiltersVisible: false,
  listing: null,
  homepageListings: {},
  myListings: [],
  myListingsFetching: false,
  favoriteListings: [],
  favoriteListingsFetching: false,
  saveListingPending: false,
  getPriceStatsPending: false,
  priceStats: {},
  getCountPending: false,
  filtersCount: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_SALE_PENDING': {
      state = {
        ...state,
        listing: null,
      }
      break
    }
    case 'GET_SALE_FULFILLED': {
      let listing = action.payload

      if (!listing.photos.length) {
        listing.photos.push(`https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${listing.address}, New York&key=${GOOGLE_API_KEY}`)
      }

      state = {
        ...state,
        listing: action.payload,
      }
      break
    }
    case 'GET_EDIT_SALE_PENDING': {
      state = {
        ...state,
        editListing: null,
      }
      break
    }
    case 'GET_EDIT_SALE_FULFILLED': {
      state = {
        ...state,
        editListing: action.payload,
      }
      break
    }
    case 'SEND_SELL_CONTACT_PENDING': {
      state = {
        ...state,
        sendSellContactPending: true,
      }
      break
    }
    case 'SEND_SELL_CONTACT_FULFILLED': {
      state = {
        ...state,
        sendSellContactPending: false,
      }
      swal('SUCCESS', 'Thanks for your inquiry. We‘ll contact you shortly!', 'success')
      break
    }
    case 'SEND_SELL_CONTACT_REJECTED': {
      state = {
        ...state,
        sendSellContactPending: false,
      }

      const err = action.payload

      if (err.data && err.data.error) {
        swal('ERROR', err.data.error, 'error')
      } else {
        swal('ERROR', 'Something went wrong. Please try again later!', 'error')
      }

      break
    }
    case 'SEND_REBATE_CONTACT_PENDING': {
      state = {
        ...state,
        sendRebateContactPending: true,
      }
      break
    }
    case 'SEND_REBATE_CONTACT_FULFILLED': {
      state = {
        ...state,
        sendRebateContactPending: false,
      }
      swal('SUCCESS', 'Thanks for your inquiry. We‘ll contact you shortly!', 'success')
      break
    }
    case 'SEND_REBATE_CONTACT_REJECTED': {
      state = {
        ...state,
        sendRebateContactPending: false,
      }

      const err = action.payload

      if (err.data && err.data.error) {
        swal('ERROR', err.data.error, 'error')
      } else {
        swal('ERROR', 'Something went wrong. Please try again later!', 'error')
      }

      break
    }
    case 'SAVE_SALE_PENDING': {
      state = {
        ...state,
        saveListingPending: true,
      }
      break
    }
    case 'SAVE_SALE_FULFILLED': {
      state = {
        ...state,
        saveListingPending: false,
      }
      break
    }
    case 'SAVE_SALE_REJECTED': {
      state = {
        ...state,
        saveListingPending: false,
      }

      const err = action.payload

      if (err.data && err.data.error) {
        swal('ERROR', err.data.error, 'error')
      } else {
        swal('ERROR', 'Something went wrong. Please try again later!', 'error')
      }

      break
    }
    case 'EXTEND_SALE_PENDING': {
      state = {
        ...state,
        myListingsFetching: true,
        extendListingPending: true,
      }
      break
    }
    case 'EXTEND_SALE_FULFILLED': {
      const newExtendedListing = action.payload

      if (newExtendedListing) {
        let newMyListings = [...state.myListings]

        const foundIndex = newMyListings.findIndex(val => val.id === newExtendedListing.id)

        newMyListings[foundIndex] = newExtendedListing

        state = {
          ...state,
          myListings: newMyListings,
          myListingsFetching: false,
          extendListingPending: false,
        }
      }
      break
    }
    case 'EXTEND_SALE_REJECTED': {
      state = {
        ...state,
        extendListingPending: false,
        myListingsFetching: false,
      }
      break
    }
    case 'GET_HOMEPAGE_SALES_FULFILLED': {
      state = {
        ...state,
        homepageListings: {
          condosList: [...action.payload[0]],
          condosTotal: action.payload[0].length && action.payload[0][0].total_items,
          townhousesList: [...action.payload[1]],
          townhousesTotal: action.payload[1].length && action.payload[1][0].total_items,
          manhattanList: [...action.payload[2]],
          manhattanTotal: action.payload[2].length && action.payload[2][0].total_items,
        },
      }
      break
    }
    case 'GET_SALE_PRICE_STATS_PENDING': {
      state = {
        ...state,
        getPriceStatsPending: true,
        priceStats: {},
      }
      break
    }
    case 'GET_SALE_PRICE_STATS_FULFILLED': {
      state = {
        ...state,
        getPriceStatsPending: false,
        priceStats: action.payload || {},
      }
      break
    }
    case 'GET_SALE_PRICE_STATS_REJECTED': {
      state = {
        ...state,
        getPriceStatsPending: false,
      }
      break
    }
    case 'GET_SALE_COUNT_PENDING': {
      state = {
        ...state,
        getCountPending: true,
        filtersCount: null,
      }
      break
    }
    case 'GET_SALE_COUNT_FULFILLED': {
      state = {
        ...state,
        getCountPending: false,
        filtersCount: (action.payload && action.payload.total_items) || null,
      }
      break
    }
    case 'GET_SALE_COUNT_REJECTED': {
      state = {
        ...state,
        getCountPending: false,
        filtersCount: null,
      }
      break
    }
    case 'GET_BROWSE_SALES_PENDING': {
      state = {
        ...state,
        listingsFetching: true,
      }
      if (action.meta) {
        state.filters = action.meta.filters
      }
      break
    }
    case 'GET_BROWSE_SALES_FULFILLED': {
      let listings = action.payload && action.payload
      const totalListings = (action.payload && action.payload[0] && action.payload[0].total_items) || 0
      const minPrice = action.payload && action.payload.min_price
      const avgPrice = action.payload && action.payload.avg_price
      const maxPrice = action.payload && action.payload.max_price

      listings.map(listing => {
        if (!listing.photos.length) {
          listing.photos.push(`https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${listing.address}, New York&key=${GOOGLE_API_KEY}`)
        }

        return null
      })

      state = {
        ...state,
        listings: [...listings],
        totalListings,
        minPrice,
        maxPrice,
        avgPrice,
        listingsFetching: false,
      }
      break
    }
    case 'GET_BROWSE_SALES_REJECTED': {
      if (!(action.payload && action.payload.isCancel)) {
        state = {
          ...state,
          listingsFetching: false,
        }
      }
      break
    }
    case 'GET_SIMILAR_SALES_FULFILLED': {
      state = {
        ...state,
        similarListings: [...action.payload],
      }

      break
    }
    case 'RESET_BROWSE_SALES': {
      state = {
        ...state,
        listings: [],
        totalListings: null,
        filters: {...defaultState.filters},
        moreFilters: {},
        moreFiltersVisible: false,
        neighborhoodsFiltersVisible: false,
      }
      break
    }
    case 'RESET_SALE': {
      state = {
        ...state,
        listing: null,
        similarListings: [],
      }
      break
    }
    case 'OPEN_MORE_SALE_FILTERS': {
      const filterBy = state.filters.filter_by

      state = {
        ...state,
        moreFilters: {
          amenities: filterBy.amenities,
          amenities_slug: filterBy.amenities_slug,
          bathrooms : filterBy.bathrooms,
          bathroom: filterBy.bathroom,
          pet_policy: filterBy.pet_policy,
          subway_lines: filterBy.subway_lines,
          open_houses: filterBy.open_houses,
          building_types: filterBy.building_types,
          price: {
            min: filterBy.price && filterBy.price.min,
            max: filterBy.price && filterBy.price.max,
          },
        },
        moreFiltersVisible: true,
      }

      break
    }
    case 'SET_MORE_SALE_FILTERS': {
      state = {
        ...state,
        moreFilters: {...action.payload},
      }
      break
    }
    case 'CLOSE_MORE_SALE_FILTERS': {
      state = {
        ...state,
        moreFiltersVisible: false,
        moreFilters: null,
      }

      break
    }
    case 'GET_MY_PRO_SALES_PENDING': {
      state = {
        ...state,
        myListingsFetching: true,
      }
      break
    }
    case 'GET_MY_PRO_SALES_REJECTED': {
      state = {
        ...state,
        myListingsFetching: false,
      }
      break
    }
    case 'GET_MY_PRO_SALES_FULFILLED': {
      state = {
        ...state,
        myListings: [...state.myListings, ...action.payload],
        myListingsFetching: false,
      }
      break
    }
    case 'GET_MY_PRO_RENTALS_PENDING':
    case 'GET_MY_RENTALS_PENDING':
    case 'RESET_MY_SALES': {
      state = {
        ...state,
        myListings: [],
      }
      break
    }
    case 'FAVORITE_SALE_PENDING': {
      const listingId = action.meta.sale.id
      let newSale = {
        ...action.meta.sale,
        favorite: !action.meta.sale.favorite,
      }

      const updateNewListing = listing => listingId === listing.id ? newSale : listing

      const foundFavoriteIndex = state.favoriteListings.findIndex(listing => listing.id === listingId)
      let newFavoriteListings = [...state.favoriteListings]
      newFavoriteListings.splice(foundFavoriteIndex, 1)

      const newListings = state.listings.map(updateNewListing)
      const newMyListings = state.myListings.map(updateNewListing)
      const newListing = state.listing && state.listing.id === listingId ? newSale : state.listing

      state = {
        ...state,
        listings: newListings,
        favoriteListings: newFavoriteListings,
        myListings: newMyListings,
        listing: newListing,
      }

      break
    }
    case 'FAVORITE_SALE_REJECTED': {
      swal('ERROR', 'Something went wrong while updating the favorite listings. Please refresh the page and try again.', 'error')
      break
    }
    case 'GET_FAVORITE_SALES_PENDING': {
      state = {
        ...state,
        favoriteListingsFetching: true,
      }
      break
    }
    case 'GET_FAVORITE_SALES_FULFILLED': {
      state = {
        ...state,
        favoriteListings: [...action.payload],
        favoriteListingsFetching: false,
      }
      break
    }
    case 'GET_FAVORITE_SALES_REJECTED': {
      state = {
        ...state,
        favoriteListingsFetching: false,
      }
      break
    }
    case 'RESET_FAVORITE_SALES': {
      state = {
        ...state,
        favoriteListings: [],
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
