import qs from 'qs'
import { push } from 'react-router-redux'

import buildingService from '../services/buildingService'

const getFiltersFromUrl = (queryString, extraFilters) => {
  const queryFilters = qs.parse(decodeURI(queryString), {
    decoder: str => {
      const floatStr = parseFloat(str)

      if (!isNaN(floatStr) && str === floatStr) {
        str = floatStr
      } else if (str === 'true') {
        str = true
      } else if (str === 'false') {
        str = false
      }

      return str
    },
    allowDots: true,
    arrayLimit: 200,
  }) || {}

  return {
    page: queryFilters.page || 1,
    building_types: queryFilters.building_types,
    sort_by: queryFilters.sort_by || undefined,
    ...extraFilters,
  }
}

export const getBrowseBuildings = (queryString, extraFilters) => {
  const filters = getFiltersFromUrl(queryString, extraFilters)

  return {
    type: 'GET_BROWSE_BUILDINGS',
    payload: buildingService.getBrowseBuildings(filters),
    meta: {
      filters,
    },
  }
}

export const getAllBuildings = () => {
  return {
    type: 'GET_ALL_BUILDINGS',
    payload: buildingService.getAllBuildings(),
  }
}

export const getBuildings = () => {
  return {
    type: 'GET_BUILDINGS',
    payload: buildingService.getBuildings(),
  }
}

export const resetBrowseBuildings = () => {
  buildingService.cancelGetBrowseBuildings()

  return {
    type: 'RESET_BROWSE_BUILDINGS',
  }
}

export const getBuilding = buildingId => {
  return {
    type: 'GET_BUILDING',
    payload: buildingService.getBuilding(buildingId),
  }
}

export const getSimilarBuildings = buildingId => {
  return {
    type: 'GET_SIMILAR_BUILDINGS',
    payload: buildingService.getSimilarBuildings(buildingId),
  }
}

export const removeBuilding = buildingId => {
  return {
    type: 'REMOVE_BUILDING',
    payload: buildingService.removeBuilding(buildingId),
  }
}

export const getEditBuilding = buildingId => {
  return {
    type: 'GET_EDIT_BUILDING',
    payload: buildingService.getBuilding(buildingId),
  }
}

export const resetEditBuilding = () => {
  return {
    type: 'GET_EDIT_BUILDING_FULFILLED',
    payload: null,
  }
}

export const createBuildingReview = building => {
  return {
    type: 'CREATE_BUILDING_REVIEW',
    payload: buildingService.createBuildingReview(building),
  }
}

export const saveBuilding = building => {
  return (dispatch => {
    dispatch({
      type: 'SAVE_BUILDING_PENDING',
    })

    buildingService.saveBuilding(building).then(data => {
      dispatch({
        type: 'SAVE_BUILDING_FULFILLED',
      })
      dispatch(push('/my-buildings'))
    }, err => {
      dispatch({
        type: 'SAVE_BUILDING_REJECTED',
        payload: err,
      })
    })
  })
}

export const updateBuilding = building => {
  return (dispatch => {
    dispatch({
      type: 'UPDATE_BUILDING_PENDING',
    })

    buildingService.updateBuilding(building).then(data => {
      dispatch({
        type: 'UPDATE_BUILDING_FULFILLED',
      })
      dispatch(push('/my-buildings'))
    }, err => {
      dispatch({
        type: 'UPDATE_BUILDING_REJECTED',
        payload: err,
      })
    })
  })
}

export const updateBuildingWithPromise = building => {
  return {
    type: 'UPDATE_BUILDING',
    payload: buildingService.updateBuilding(building),
  }
}

export const resetBuilding = () => {
  return {
    type: 'RESET_BUILDING',
  }
}

export const getMyBuildings = (loggedUser) => {
  if (loggedUser.is_pro) {
    return {
      type: 'GET_MY_BUILDINGS',
      payload: buildingService.getMyBuildings(loggedUser),
    }
  }
}

export const resetMyBuildings = () => {
  return {
    type: 'RESET_MY_BUILDINGS',
  }
}
