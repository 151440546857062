import brokerService from '../services/brokerService'
import rentalService from '../services/rentalService'
import saleService from '../services/saleService'

export const getBroker = (brokerSlug) => {
  return {
    type: 'GET_BROKER',
    payload: brokerService.getBroker(brokerSlug),
  }
}

export const getBrokerRentals = (brokerId) => {
  return {
    type: 'GET_BROKER_RENTALS',
    payload: rentalService.getBrokerListings(brokerId)
  }
}

export const getBrokerSales = (brokerId) => {
  return {
    type: 'GET_BROKER_SALES',
    payload: saleService.getBrokerListings(brokerId)
  }
}
