import { push, replace } from 'react-router-redux'

import zoneService from '../services/zoneService'
import seoContentService from '../services/seoContentService'

export const setErrorStatus = (errorStatus) => {
  return {
    type: 'SET_ERROR_STATUS',
    payload: errorStatus
  }
}

export const setMobileMenuVisibility = (visibility) => {
  return {
    type: 'SET_MOBILE_MENU_VISIBILITY',
    payload: visibility
  }
}

export const goToRoute = (route) => {
  return (dispatch) => {
    dispatch(push(route))
  }
}

export const replaceRoute = (route) => {
  return (dispatch) => {
    dispatch(replace(route))
  }
}

export const setListingPhotoCarouselIndex = photoIndex => {
  return {
    type: 'SET_LISTING_PHOTO_CAROUSEL_INDEX',
    payload: photoIndex,
  }
}

export const getZones = () => {
  return {
    type: 'GET_ZONES',
    payload: zoneService.getZones(),
  }
}

export const setPageStatus = status => {
  return {
    type: 'SET_PAGE_STATUS',
    payload: status,
  }
}

export const getSeoContent = (listingType, areaTerm, filterTerm) => {
  return {
    type: 'GET_SEO_CONTENT',
    payload: seoContentService.getSeoContent(listingType, areaTerm, filterTerm),
  }
}

export const resetSeoContent = () => {
  return {
    type: 'GET_SEO_CONTENT_FULFILLED',
    payload: null,
  }
}
