import React from 'react'

export default props => {
  const {
    user,
    showElite,
  } = props

  let userImage = null
  let userInitials = null

  if (!user) return null

  if (user.picture && user.picture !== 'https://s3.amazonaws.com/leasehopphotos/resources/default-avatar.png') {
    userImage = user.picture
  } else {
    userInitials = user.first_name[0] + user.last_name[0]
  }

  return (
    <div
      className="user-avatar"
      style={userImage && {
        'backgroundImage': `url(${userImage})`
      }}>
      { userInitials && <span className="user-initials">{ userInitials }</span> }
      {
        !!user.featured && showElite &&
        <div className="elite-badge">
          <span>
            <i className="elite-icon nc-icon-mini ui-2_favourite-31"></i>Elite
          </span>
        </div>
      }
    </div>
  )
}
