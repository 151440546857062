/* global CLIENT */

import axios from 'axios'

import { RequestService } from '../store'

import * as constants from '../helpers/constants'

const CancelToken = axios.CancelToken
let cancel, cancelFiltersStats, cancelFiltersCount

class RentalService {
  getListing(listingId, params) {
    return RequestService.get('/lease/' + listingId, params)
  }

  removeListing(listingId) {
    return RequestService.delete('/lease/' + listingId, null)
  }

  getBrokerListings(brokerId) {
    return RequestService.post('/lease/filter?total_items=3&page=1', null, {
      filter_by: {
        listed_by: brokerId,
      },
    })
  }

  getHomepageListings(filters) {
    return Promise.all([
      RequestService.post('/lease/filter', {
        total_items: 3,
        page: 1,
      }, {
        filter_by: {...filters[0]},
      }),
      RequestService.post('/lease/filter', {
        total_items: 3,
        page: 3,
      }, {
        filter_by: {...filters[1]},
      }),
      RequestService.post('/lease/filter', {
        total_items: 3,
        page: 5,
      }, {
        filter_by: {...filters[2]},
      }),
    ])
  }

  getRoomsPreviewListings() {
    return RequestService.post('/lease/filter', {
      total_items: 3,
      page: 1,
    }, {
      filter_by: {
        home_type: 'single_room',
      },
    })
  }

  saveRental(data) {
    return RequestService.post('/lease', null, data)
  }

  updateRental(data) {
    return RequestService.put('/lease/' + data.id, null, data)
  }

  getSimilarRentals(rentalId) {
    return RequestService.get('/lease/' + rentalId + '/similar')
  }

  getFavoriteRentals(userId) {
    return RequestService.get('/lease/' + userId + '/my_favorites')
  }

  cancelGetBrowseRentals(filters) {
    if (cancel) {
      cancel()
    }
  }

  getBrowseRentals(filters) {
    if (cancel && __CLIENT__) {
      cancel()
    }

    let newFilters = {...filters}

    if (newFilters.filter_by.bbox && newFilters.filter_by.bbox.sw) {
      const height = Math.abs(newFilters.filter_by.bbox.sw[0] - newFilters.filter_by.bbox.ne[0])
      const width = Math.abs(newFilters.filter_by.bbox.sw[1] - newFilters.filter_by.bbox.ne[1])

      newFilters.filter_by.bbox.ne[0] = parseFloat(newFilters.filter_by.bbox.ne[0]) - parseFloat(height / 6)
      newFilters.filter_by.bbox.sw[0] = parseFloat(newFilters.filter_by.bbox.sw[0]) + parseFloat(height / 8)

      newFilters.filter_by.bbox.ne[1] = parseFloat(newFilters.filter_by.bbox.ne[1]) - parseFloat(width / 5)
      newFilters.filter_by.bbox.sw[1] = parseFloat(newFilters.filter_by.bbox.sw[1]) + parseFloat(width / 5)
    }

    return RequestService.post('/lease/filter', {
        order_featured: true,
        featured_items: 0,
        total_items: constants.browseListingsPerPage,
        page: (filters && filters.page) || 1,
      }, newFilters, {
        cancelToken: new CancelToken(function executor (c) {
          cancel = c;
        }),
      },
    )
  }

  getRentalPriceStats(filters) {
    if (cancelFiltersStats) {
      cancelFiltersStats()
    }

    let newFilters = {...filters}

    return RequestService.post('/lease/price_stats', {
      }, newFilters, {
        cancelToken: new CancelToken(function executor (c) {
          cancelFiltersStats = c
        }),
      },
    )
  }

  getRentalCount(filters) {
    if (cancelFiltersCount) {
      cancelFiltersCount()
    }

    let newFilters = {...filters}

    return RequestService.post('/lease/count', {
      }, newFilters, {
        cancelToken: new CancelToken(function executor (c) {
          cancelFiltersCount = c
        }),
      },
    )
  }

  getMyRentals(loggedUser, homeType) {
    return RequestService.get(`/lease/${loggedUser.id}/my_listings?home_type=${homeType || 'entire_place'}`)
  }

  getMyProRentals(loadedListings, homeType) {
    return RequestService.get('/pro_lease', {
      total_items: 18,
      page: loadedListings ? loadedListings / 18 + 1 : 1,
      home_type: homeType || 'entire_place',
    })
  }

  toggleUnpublishRental(rental, unpublished) {
    rental.unpublished = unpublished

    return RequestService.put('/lease/' + rental.id + '/unpublish', null, rental)
  }

  extendListing(data) {
    return RequestService.put('/lease/' + data.id + '/extend', null, data)
  }
}

export default new RentalService()
