/* global swal */

const defaultState = {
  conversations: [],
  conversationsFetching: false,
  conversation: null,
  activeConversationId: null,
  unreadConversations: 0,
  createConversationPending: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_CONVERSATIONS_PENDING': {
      state = {
        ...state,
        conversationsFetching: true,
      }
      break
    }
    case 'GET_CONVERSATIONS_FULFILLED': {
      state = {
        ...state,
        conversations: [...action.payload.conversations],
        conversationsFetching: false,
      }
      break
    }
    case 'GET_CONVERSATIONS_REJECTED': {
      state = {
        ...state,
        conversationsFetching: false,
      }
      break
    }
    case 'GET_CONVERSATION_PENDING': {
      state = {
        ...state,
        activeConversationId: action.payload,
        conversation: null,
      }
      break
    }
    case 'GET_CONVERSATION_FULFILLED': {
      state = {
        ...state,
        conversation: action.payload.conversation,
      }
      break
    }
    case 'RESET_CONVERSATIONS': {
      state = {
        ...state,
        conversations: [],
        activeConversationId: null,
      }
      break
    }
    case 'RESET_CONVERSATION': {
      state = {
        ...state,
        conversation: null,
      }
      break
    }
    case 'SEND_MESSAGE_PENDING': {
      state = {
        ...state,
        sendMessagePending: true,
      }
      break
    }
    case 'SEND_MESSAGE_FULFILLED': {
      state = {
        ...state,
        sendMessagePending: true,
      }
      break
    }
    case 'SEND_MESSAGE_REJECTED': {
      state = {
        ...state,
        sendMessagePending: false,
      }
      break
    }
    case 'SET_UNREAD_CONVERSATIONS': {
      state = {
        ...state,
        unreadConversations: action.payload,
      }
      break
    }
    case 'CREATE_CONVERSATION_PENDING': {
      state = {
        ...state,
        createConversationPending: true,
      }
      break
    }
    case 'CREATE_CONVERSATION_FULFILLED': {
      state = {
        ...state,
        createConversationPending: false,
      }
      break
    }
    case 'CREATE_CONVERSATION_REJECTED': {
      state = {
        ...state,
        createConversationPending: false,
      }
      swal('ERROR', 'Something went wrong. Please try again!', 'error')
      break
    }
    default: {
      break
    }
  }

  return state
}
