import Loadable from 'react-loadable'
// import trim from 'lodash/trim'
import isEmpty from 'lodash/isEmpty'

import App from './pages/App'

// import Home from './pages/Home'
const BrowseRentals = Loadable({
  loader: () => import('./pages/BrowseRentals'),
  loading: () => null,
})
const BrowseSales = Loadable({
  loader: () => import('./pages/BrowseSales'),
  loading: () => null,
})
const BrowseRooms = Loadable({
  loader: () => import('./pages/BrowseRooms'),
  loading: () => null,
})
const BrowseBuildings = Loadable({
  loader: () => import('./pages/BrowseBuildings'),
  loading: () => null,
})
// const Neighborhood = Loadable({
//   loader: () => import('./pages/Neighborhood'),
//   loading: () => null,
// })
const NeighborhoodGuide = Loadable({
  loader: () => import('./pages/NeighborhoodGuide'),
  loading: () => null,
})
// const NeighborhoodPopularSearches = Loadable({
//   loader: () => import('./pages/NeighborhoodPopularSearches'),
//   loading: () => null,
// })
const Rental = Loadable({
  loader: () => import('./pages/Rental'),
  loading: () => null,
})
const Sale = Loadable({
  loader: () => import('./pages/Sale'),
  loading: () => null,
})
const Building = Loadable({
  loader: () => import('./pages/Building'),
  loading: () => null,
})
const SetNewPassword = Loadable({
  loader: () => import('./pages/SetNewPassword'),
  loading: () => null,
})
const Terms = Loadable({
  loader: () => import('./pages/Terms'),
  loading: () => null,
})
const TermsListers = Loadable({
  loader: () => import('./pages/TermsListers'),
  loading: () => null,
})
const TermsPrivacy = Loadable({
  loader: () => import('./pages/TermsPrivacy'),
  loading: () => null,
})
const TermsRenters = Loadable({
  loader: () => import('./pages/TermsRenters'),
  loading: () => null,
})
const InitialSetup = Loadable({
  loader: () => import('./pages/InitialSetup'),
  loading: () => null,
})
const NewListing = Loadable({
  loader: () => import('./pages/NewListing'),
  loading: () => null,
})
const NewRoom = Loadable({
  loader: () => import('./pages/NewRoom'),
  loading: () => null,
})
const NewBuilding = Loadable({
  loader: () => import('./pages/NewBuilding'),
  loading: () => null,
})
const EditBuilding = Loadable({
  loader: () => import('./pages/EditBuilding'),
  loading: () => null,
})
const Blog = Loadable({
  loader: () => import('./pages/Blog'),
  loading: () => null,
})
const Article = Loadable({
  loader: () => import('./pages/Article'),
  loading: () => null,
})
const Author = Loadable({
  loader: () => import('./pages/Author'),
  loading: () => null,
})
// const DynamicBrowseListings = Loadable({
//   loader: () => import('./pages/DynamicBrowseListings'),
//   loading: () => null,
// })
const MatesHomepage = Loadable({
  loader: () => import('./pages/MatesHomepage'),
  loading: () => null,
})
const BrowseRoommates = Loadable({
  loader: () => import('./pages/BrowseRoommates'),
  loading: () => null,
})
const Roommate = Loadable({
  loader: () => import('./pages/Roommate'),
  loading: () => null,
})
const EditRoommate = Loadable({
  loader: () => import('./pages/EditRoommate'),
  loading: () => null,
})
const Sell = Loadable({
  loader: () => import('./pages/Sell'),
  loading: () => null,
})
const Rebate = Loadable({
  loader: () => import('./pages/Rebate'),
  loading: () => null,
})
const AllRentals = Loadable({
  loader: () => import('./pages/AllRentals'),
  loading: () => null,
})
const AllSales = Loadable({
  loader: () => import('./pages/AllSales'),
  loading: () => null,
})
const AllBuildings = Loadable({
  loader: () => import('./pages/AllBuildings'),
  loading: () => null,
})

const EditListing = Loadable({
  loader: () => import('./pages/EditListing'),
  loading: () => null,
})
const EditRoom = Loadable({
  loader: () => import('./pages/EditRoom'),
  loading: () => null,
})
const AccountSettings = Loadable({
  loader: () => import('./pages/AccountSettings'),
  loading: () => null,
})
const Conversation = Loadable({
  loader: () => import('./pages/Conversation'),
  loading: () => null,
})
const Conversations = Loadable({
  loader: () => import('./pages/Conversations'),
  loading: () => null,
})
const FavoriteListings = Loadable({
  loader: () => import('./pages/FavoriteListings'),
  loading: () => null,
})
const MyListings = Loadable({
  loader: () => import('./pages/MyListings'),
  loading: () => null,
})
const MyBuildings = Loadable({
  loader: () => import('./pages/MyBuildings'),
  loading: () => null,
})
const MyRooms = Loadable({
  loader: () => import('./pages/MyRooms'),
  loading: () => null,
})
const MyViewings = Loadable({
  loader: () => import('./pages/MyViewings'),
  loading: () => null,
})
const ConfirmViewing = Loadable({
  loader: () => import('./pages/ConfirmViewing'),
  loading: () => null,
})
const LoanAmortizationCalculator = Loadable({
  loader: () => import('./pages/LoanAmortizationCalculator'),
  loading: () => null,
})
const BiWeeklyCalculator = Loadable({
  loader: () => import('./pages/BiWeeklyCalculator'),
  loading: () => null,
})

import ErrorPage from './pages/ErrorPage'
import IsLoggedIn from './components/IsLoggedIn'

import * as actions from './actions'
import * as helpers from './helpers'

const Home = Loadable({
  loader: () => import('./pages/Home'),
  loading: () => null,
});

const routes = [
  {
    component: App,
    fetchData: ({dispatch}) => {
      return dispatch(actions.setInitialUser())
    },
    routes: [
      {
        path: '/',
        component: Home,
        name: 'home',
        exact: true,
        theme: 'transparent',
        hideFooter: true,
        showFeaturedLinksFooter: 'rental',
        fetchData: ({dispatch, getState}, {params}) => {
          return new Promise((resolve, reject) => {
            const rentalFilters = [
              helpers.getFiltersFromTerm('nyc', 'no-fee'),
              helpers.getFiltersFromTerm('nyc', 'pet-friendly'),
              helpers.getFiltersFromTerm('nyc', 'luxury'),
            ]

            const promises = [
              dispatch(actions.getAllGroupedNeighborhoods()),
              dispatch(actions.getAllNeighborhoodGuide()),
              dispatch(actions.getHomepageRentals(rentalFilters)),
            ]

            resolve(Promise.all(promises))
          })
        },
      },
      {
        path: '/buy',
        component: Home,
        name: 'home',
        exact: true,
        theme: 'transparent',
        hideFooter: true,
        showFeaturedLinksFooter: 'sale',
        fetchData: ({dispatch, getState}, {params}) => {
          return new Promise((resolve, reject) => {
            const promises = [
              dispatch(actions.getAllGroupedNeighborhoods()),
              dispatch(actions.getAllNeighborhoodGuide()),
            ]

            const firstPromiseCallback = () => {
              const state = getState()
              const neighborhoods = state.neighborhood.groupedNeighborhoods

              const saleFilters = [
                {building_types: ['Condo']},
                {building_types: ['Townhouse']},
                helpers.getFiltersFromTerm('manhattan', '', neighborhoods),
              ]

              const secondPromises = [
                dispatch(actions.getHomepageSales(saleFilters)),
              ]

              resolve(Promise.all(secondPromises))
            }

            Promise.all(promises)
            .then(firstPromiseCallback)
            .catch(firstPromiseCallback)
          })
        },
      },/*
      {
        path: '/list-your-sale',
        component: Home,
        name: 'home',
        exact: true,
        theme: 'transparent',
        hideFooter: true,
        showFeaturedLinksFooter: 'sale',
      },*/
      {
        path: '/coliving',
        component: MatesHomepage,
        name: 'mates',
        exact: true,
        theme: 'transparent',
        hideFooter: true,
        fetchData: ({dispatch, getState}, match, query) => {
          const promises = [
            dispatch(actions.getRoomsPreviewListings()),
          ]

          return Promise.all(promises)
        }
      },
      {
        path: '/sell',
        component: Sell,
        name: 'sell',
        exact: true,
        theme: 'transparent',
        hideFooter: true,
      },
      {
        path: '/mortgage-loan-amortization-calculator',
        component: LoanAmortizationCalculator,
        name: 'mortgage-calculator',
        exact: true,
        theme: 'transparent',
      },
      {
        path: '/bi-weekly-mortgage-loan-calculator',
        component: BiWeeklyCalculator,
        name: 'mortgage-calculator',
        exact: true,
        theme: 'transparent',
      },
      {
        path: '/buyer-commission-rebate',
        component: Rebate,
        name: 'rebate',
        exact: true,
        theme: 'transparent',
        hideFooter: true,
        fetchData: ({dispatch, getState}, {params}) => {
          const promises = [
            dispatch(actions.getAllGroupedNeighborhoods()),
          ]

          return Promise.all(promises)
        },
      },
      {
        path: '/nyc-roommates',
        component: BrowseRoommates,
        name: 'roommates',
        exact: true,
        fetchData: ({dispatch, getState}, match, query) => {
          if (!match) {
            return null
          }

          const promises = [
            dispatch(actions.getRoommates()),
          ]

          return Promise.all(promises)
        },
      },
      {
        path: '/roommate/:roommateId',
        component: Roommate,
        name: 'roommate',
        exact: true,
        fetchData: ({dispatch}, {params}) => {
          return dispatch(actions.getRoommate(params.roommateId))
        }
      },
      {
        path: '/broker/:brokerId',
        component: ErrorPage,
        name: 'broker',
        exact: true,
        fetchData: ({dispatch}, {params}) => {
          return dispatch(actions.setPageStatus(410))
          // const promises = [
          //   dispatch(actions.getBroker(params.brokerId)),
          //   dispatch(actions.getBrokerRentals(params.brokerId)),
          //   dispatch(actions.getBrokerSales(params.brokerId)),
          // ]

          // return Promise.all(promises)
        }
      },
      {
        path: '/author/:authorSlug',
        component: Author,
        name: 'author',
        exact: true,
        fetchData: ({ dispatch }, { params }, query) => {
          const promises = [
            dispatch(actions.getAuthor(params.authorSlug)),
            dispatch(actions.getBrowseArticles(query, {
              author: params.authorSlug,
            }))
          ]

          return Promise.all(promises)
        }
      },
      {
        path: '/for-rent/:areaTerm/:filterTerm?',
        component: BrowseRentals,
        name: 'listings',
        exact: false,
        fetchData: ({dispatch, getState}, match, query) => {
          if (!match) {
            return null
          }

          const areaTerm = match.params.areaTerm
          const filterTerm = match.params.filterTerm

          dispatch(actions.pendingBrowseRentals())

          if (areaTerm === 'nyc' && !filterTerm) {
            const promises = [
              dispatch(actions.getBrowseRentals(query, 'nyc', true)),
              dispatch(actions.getSubwayLines()),
              dispatch(actions.getAllGroupedNeighborhoods()),
              dispatch(actions.getSeoContent('for-rent', areaTerm, filterTerm))
            ]

            return Promise.all(promises)
          } else {
            return new Promise((resolve, reject) => {
              const promises = [
                dispatch(actions.getSubwayLines()),
                dispatch(actions.getAllGroupedNeighborhoods()),
                dispatch(actions.getSeoContent('for-rent', areaTerm, filterTerm)),
              ]

              if (areaTerm === 'manhattan' || areaTerm === 'queens' || areaTerm === 'brooklyn') {
                promises.push(dispatch(actions.getAllNeighborhoodGuide({
                  guide: false,
                  is_popular_rent: 'true',
                  is_popular_sale: 'false',
                  sublocality: areaTerm[0].toUpperCase() + areaTerm.slice(1),
                })))
              }

              const firstPromiseCallback = () => {
                const state = getState()
                const neighborhoods = state.neighborhood.groupedNeighborhoods
                const isLimitedPage = state.app.seoContent && state.app.seoContent.is_page_limited
                const filtersFromTerm = helpers.getFiltersFromTerm(areaTerm, filterTerm, neighborhoods)

                if (!filtersFromTerm || isEmpty(filtersFromTerm)) {
                  resolve(dispatch(actions.setPageStatus(404)))
                  return
                }

                const secondPromises = [
                  dispatch(actions.getBrowseRentals(query, filtersFromTerm, isLimitedPage)),
                ]

                if (areaTerm) {
                  secondPromises.push(dispatch(actions.getOneNeighborhoodGuidePreview(areaTerm)).catch(() => {
                    return
                  }))
                  secondPromises.push(dispatch(actions.getSimilarNeighborhoods(areaTerm, 12)).catch(() => {
                    return
                  }))
                }

                resolve(Promise.all(secondPromises))
              }

              Promise.all(promises)
              .then(firstPromiseCallback)
              .catch(firstPromiseCallback)
            })
          }
        }
      },
      {
        path: '/for-sale/:areaTerm/:filterTerm?',
        component: BrowseSales,
        name: 'listings',
        exact: false,
        fetchData: ({dispatch, getState}, match, query) => {
          if (!match) {
            return null
          }

          const areaTerm = match.params.areaTerm
          const filterTerm = match.params.filterTerm

          dispatch(actions.pendingBrowseSales())

          if (areaTerm === 'nyc' && !filterTerm) {
            const promises = [
              dispatch(actions.getBrowseSales(query, 'nyc', true)),
              dispatch(actions.getSubwayLines()),
              dispatch(actions.getAllGroupedNeighborhoods()),
              dispatch(actions.getSeoContent('for-sale', areaTerm, filterTerm))
            ]

            return Promise.all(promises)
          } else {
            return new Promise((resolve, reject) => {
              const promises = [
                dispatch(actions.getSubwayLines()),
                dispatch(actions.getAllGroupedNeighborhoods()),
                dispatch(actions.getSeoContent('for-sale', areaTerm, filterTerm)),
              ]

              if (areaTerm === 'manhattan' || areaTerm === 'queens' || areaTerm === 'brooklyn') {
                promises.push(dispatch(actions.getAllNeighborhoodGuide({
                  guide: false,
                  is_popular_rent: 'false',
                  is_popular_sale: 'true',
                  sublocality: areaTerm[0].toUpperCase() + areaTerm.slice(1),
                })))
              }

              const firstPromiseCallback = () => {
                const state = getState()
                const neighborhoods = state.neighborhood.groupedNeighborhoods
                const isLimitedPage = state.app.seoContent && state.app.seoContent.is_page_limited
                const filtersFromTerm = helpers.getFiltersFromTerm(areaTerm, filterTerm, neighborhoods)

                if (!filtersFromTerm || isEmpty(filtersFromTerm)) {
                  resolve(dispatch(actions.setPageStatus(404)))
                  return
                }

                const secondPromises = [
                  dispatch(actions.getBrowseSales(query, filtersFromTerm, isLimitedPage)),
                ]

                if (areaTerm) {
                  secondPromises.push(dispatch(actions.getOneNeighborhoodGuidePreview(areaTerm)).catch(() => {
                    return
                  }))
                  secondPromises.push(dispatch(actions.getSimilarNeighborhoods(areaTerm, 12)).catch(() => {
                    return
                  }))
                }

                resolve(Promise.all(secondPromises))
              }

              Promise.all(promises)
              .then(firstPromiseCallback)
              .catch(firstPromiseCallback)
            })
          }
        }
      },
      {
        path: '/rooms-for-rent/:term?',
        component: BrowseRooms,
        name: 'listings',
        exact: false,
        fetchData: ({dispatch, getState}, match, query) => {
          if (!match) {
            return null
          }

        //   const term = match.params.term !== 'nyc' && match.params.term

        //   if (term) {
        //     return new Promise((resolve, reject) => {
        //       const promises = [
        //         dispatch(actions.getSubwayLines()),
        //         dispatch(actions.getAllGroupedNeighborhoods()),
        //       ]

        //       const firstPromiseCallback = () => {
        //         const state = getState()
        //         const neighborhoods = state.neighborhood.groupedNeighborhoods
        //         const filtersFromTerm = helpers.getFiltersFromTerm(term, '', neighborhoods)

        //         const secondPromises = [
        //           dispatch(actions.getBrowseRentals(query, {
        //             ...filtersFromTerm,
        //             home_type: 'single_room',
        //           })),
        //         ]

        //         resolve(Promise.all(secondPromises))
        //       }

        //       Promise.all(promises)
        //       .then(firstPromiseCallback)
        //       .catch(firstPromiseCallback)
        //     })
        //   } else {
        //     const promises = [
        //       dispatch(actions.getBrowseRentals(query, {
        //         home_type: 'single_room',
        //       })),
        //       dispatch(actions.getSubwayLines()),
        //       dispatch(actions.getAllGroupedNeighborhoods()),
        //     ]

        //     return Promise.all(promises)
        //   }
        }
      },
      {
        path: '/new-room',
        component: NewRoom,
        name: 'list',
        exact: true,
        fetchData: ({dispatch}, {params}) => {
          const promises = [
            dispatch(actions.getAmenities()),
          ]

          return Promise.all(promises)
        }
      },
      {
        path: '/edit-rental/:rentalId',
        component: EditListing,
        name: 'list',
        exact: true,
        fetchData: ({dispatch}, {params}) => {
          let promises = [
            dispatch(actions.getAmenities()),
            dispatch(actions.getEditRental(params.rentalId)),
          ]

          return Promise.all(promises)
        }
      },
      {
        path: '/edit-sale/:saleId',
        component: EditListing,
        name: 'list',
        exact: true,
        fetchData: ({dispatch}, {params}) => {
          let promises = [
            dispatch(actions.getAmenities()),
            dispatch(actions.getEditSale(params.saleId)),
          ]

          return Promise.all(promises)
        }
      },
      {
        path: '/edit-room/:rentalId',
        component: EditRoom,
        name: 'list',
        exact: true,
        fetchData: ({dispatch}, {params}) => {
          let promises = [
            dispatch(actions.getAmenities()),
            dispatch(actions.getEditRental(params.rentalId)),
          ]

          return Promise.all(promises)
        }
      },
      {
        path: '/blog',
        component: Blog,
        name: 'blog',
        exact: true,
        fetchData: ({dispatch}, {params}, query) => {
          const promises = [
            dispatch(actions.getBrowseArticles(query)),
            dispatch(actions.getFeaturedArticles()),
            dispatch(actions.getArticleTag(query)),
          ]

          return Promise.all(promises)
        }
      },
      {
        path: '/article/:articleId',
        component: Article,
        name: 'article',
        exact: true,
        fetchData: ({dispatch}, {params}) => {
          const promises = [
            dispatch(actions.getArticle(params.articleId)),
            dispatch(actions.getFeaturedArticles()),
            dispatch(actions.getSimilarArticles(params.articleId)),
          ]

          return Promise.all(promises)
        },
      },
      {
        path: ['/manhattan-neighborhoods', '/brooklyn-neighborhoods', '/queens-neighborhoods'],
        component: NeighborhoodGuide,
        name: 'neighborhoods',
        exact: true,
        fetchData: ({ dispatch }, match) => {
          const sublocalityName = match.url.replace('/', '').split('-neighborhoods')[0]

          let query = {}

          if (sublocalityName !== 'nyc') {
            query.sublocality = sublocalityName[0].toUpperCase() + sublocalityName.slice(1)
          }

          return dispatch(actions.getAllNeighborhoodGuide(query))
        }
      },
      // {
      //   path: [
      //     '/manhattan-popular-rentals', '/brooklyn-popular-rentals', '/queens-popular-rentals',
      //     '/manhattan-popular-sales', '/brooklyn-popular-sales', '/queens-popular-sales',
      //   ],
      //   component: NeighborhoodPopularSearches,
      //   name: 'neighborhoods',
      //   exact: true,
      //   fetchData: ({ dispatch }, match) => {
      //     const sublocalityName = match.url.replace('/', '').split('-popular')[0]

      //     return dispatch(actions.getAllNeighborhoodGuide({
      //       guide: false,
      //       is_popular_rent: match.url.indexOf('rentals') !== -1 ? 'true' : 'false',
      //       is_popular_sale: match.url.indexOf('sales') !== -1 ? 'true' : 'false',
      //       sublocality: sublocalityName[0].toUpperCase() + sublocalityName.slice(1),
      //     }))
      //   }
      // },
      /*{
        path: '/neighborhoods',
        component: Neighborhoods,
        name: 'neighborhoods',
        exact: true,
        fetchData: ({ dispatch }) => {
          return dispatch(actions.getAllNeighborhoodGuide())
        },
      },*/
      // {
      //   path: '/neighborhood/:neighborhoodName',
      //   component: Neighborhood,
      //   name: 'neighborhood',
      //   exact: true,
      //   theme: 'dark',
      //   fetchData: ({dispatch}, {params}) => {
      //     const promises = [
      //       dispatch(actions.getOneNeighborhoodGuide(params.neighborhoodName)),
      //       dispatch(actions.getSimilarNeighborhoods(params.neighborhoodName))
      //     ]

      //     return Promise.all(promises)
      //   }
      // },
      {
        path: '/rental/:rentalId/:rentalSlug',
        component: Rental,
        name: 'listing',
        exact: true,
        fetchData: ({dispatch}, {params}) => {
          const promises = [
            dispatch(actions.getRental(params.rentalId)),
            dispatch(actions.getSimilarRentals(params.rentalId))
          ]

          return Promise.all(promises)
        }
      },
      {
        path: '/sale/:saleId/:rentalSlug',
        component: Sale,
        name: 'listing',
        exact: true,
        fetchData: ({dispatch}, {params}) => {
          const promises = [
            dispatch(actions.getSale(params.saleId)),
            dispatch(actions.getSimilarSales(params.saleId)),
          ]

          return Promise.all(promises)
        }
      },
      {
        path: '/building/:buildingSlug',
        component: Building,
        name: 'listing',
        exact: true,
        fetchData: ({dispatch}, {params}) => {
          const promises = [
            dispatch(actions.getBuilding(params.buildingSlug)),
            dispatch(actions.getSimilarBuildings(params.buildingSlug))
          ]

          return Promise.all(promises)
        },
      },
      {
        path: '/buildings',
        component: BrowseBuildings,
        name: 'browse-buildings',
        exact: true,
        fetchData: ({dispatch, getState}, match, query) => {
          if (!match) {
            return null
          }

          const promises = [
            dispatch(actions.getBrowseBuildings(query))
          ]

          return Promise.all(promises)
        },
      },
      {
        path: '/reset-password',
        component: SetNewPassword,
        name: 'reset-password',
        exact: true,
      },
      {
        path: '/initial-setup',
        component: InitialSetup,
        name: 'initial-setup',
        exact: true,
      },
      {
        path: '/confirm-viewing',
        component: ConfirmViewing,
        name: 'confirm-viewing',
        exact: true,
      },
      {
        path: '/rentals/all',
        component: AllRentals,
        name: 'links-hub',
        exact: true,
        fetchData: ({ dispatch }) => {
          return dispatch(actions.getAllGroupedNeighborhoods())
        }
      },
      {
        path: '/sales/all',
        component: AllSales,
        name: 'links-hub',
        exact: true,
        fetchData: ({ dispatch }) => {
          return dispatch(actions.getAllGroupedNeighborhoods())
        }
      },
      {
        path: '/buildings/all',
        component: AllBuildings,
        name: 'links-hub',
        exact: true,
        fetchData: ({ dispatch }) => {
          return dispatch(actions.getAllBuildings())
        },
      },
      {
        path: '/terms',
        component: Terms,
        name: 'terms',
        theme: 'dark',
        routes: [
          {
            path: '/terms/listers',
            component: TermsListers,
            name: 'terms',
            exact: true,
          },
          {
            path: '/terms/privacy',
            component: TermsPrivacy,
            name: 'terms',
            exact: true,
          },
          {
            path: '/terms/renters',
            component: TermsRenters,
            name: 'terms',
            exact: true,
          },
        ],
      },
      {
        path: ['/new-listing', '/new-sale', '/new-rental'],
        component: NewListing,
        name: 'list',
        exact: true,
        fetchData: ({dispatch}, {params}) => {
          const promises = [
            dispatch(actions.getAmenities()),
          ]

          return Promise.all(promises)
        }
      },
      {
        component: IsLoggedIn,
        isLoggedIn: true,
        routes: [
          {
            path: '/account-settings',
            component: AccountSettings,
            name: 'account-settings',
            isLoggedIn: true,
            exact: true,
            fetchData: ({dispatch, getState}) => {
              const loggedUser = getState().user.user

              return loggedUser && dispatch(actions.getUserData(loggedUser.id))
            },
          },
          {
            path: '/roommate-form',
            component: EditRoommate,
            name: 'roommate-form',
            exact: true,
            fetchData: ({dispatch, getState}) => {
              const loggedUser = getState().user.user

              return loggedUser && dispatch(actions.getUserData(loggedUser.id))
            }
          },
          {
            path: '/inbox',
            component: Conversations,
            name: 'inbox',
            isLoggedIn: true,
            exact: true,
            fetchData: ({ dispatch, getState }) => {
              const loggedUser = getState().user.user

              return loggedUser && dispatch(actions.getConversations(loggedUser.id))
            }
          },
          {
            path: '/conversation/:conversationId',
            component: Conversation,
            name: 'conversation',
            isLoggedIn: true,
            exact: true,
            fetchData: ({ dispatch, getState }, { params }) => {
              const loggedUser = getState().user.user

              return loggedUser && dispatch(actions.getConversation(params.conversationId, loggedUser.id))
            }
          },
          {
            path: '/favorite-listings',
            component: FavoriteListings,
            name: 'my-listings',
            isLoggedIn: true,
            exact: true,
            fetchData: ({dispatch, getState}, {params}) => {
              const loggedUser = getState().user.user

              if (loggedUser) {
                const promises = [
                  dispatch(actions.getFavoriteRentals(loggedUser.id)),
                  dispatch(actions.getFavoriteSales()),
                ]

                return Promise.all(promises)
              }

              return
            },
          },
          {
            path: '/my-listings',
            component: MyListings,
            name: 'my-listings',
            isLoggedIn: true,
            exact: true,
            fetchData: ({dispatch, getState}, {params}) => {
              const state = getState()
              const myListingsExists = state.rental.myListings.length

              if (!myListingsExists && state.user.user) {
                const loggedUser = state.user.user
                return dispatch(actions.getMyRentals(loggedUser))
              }
            }
          },
          {
            path: '/my-buildings',
            component: MyBuildings,
            name: 'my-listings',
            isLoggedIn: true,
            exact: true,
            fetchData: ({dispatch, getState}, {params}) => {
              const state = getState()

              if (state.user.user) {
                const loggedUser = state.user.user
                return dispatch(actions.getMyBuildings(loggedUser))
              }

              return
            }
          },
          {
            path: '/my-rooms',
            component: MyRooms,
            name: 'my-listings',
            isLoggedIn: true,
            exact: true,
            fetchData: ({dispatch, getState}, {params}) => {
              const state = getState()

              if (state.user.user) {
                const loggedUser = state.user.user
                return dispatch(actions.getMyRentals(loggedUser, null, 'single_room'))
              }

              return
            }
          },
          {
            path: '/new-building',
            component: NewBuilding,
            name: 'new-building',
            isLoggedIn: true,
            exact: true,
            fetchData: ({dispatch}, {params}) => {
              const promises = [
                dispatch(actions.getAmenities()),
              ]

              return Promise.all(promises)
            }
          },
          {
            path: '/edit-building/:buildingId',
            component: EditBuilding,
            name: 'new-building',
            isLoggedIn: true,
            exact: true,
            fetchData: ({dispatch}, {params}) => {
              let promises = [
                dispatch(actions.getAmenities()),
              ]

              if (params.buildingId) {
                promises.push(dispatch(actions.getEditBuilding(params.buildingId)))
              }

              return Promise.all(promises)
            }
          },
          {
            path: '/my-viewings',
            component: MyViewings,
            name: 'my-listings',
            isLoggedIn: true,
            exact: true,
            fetchData: ({dispatch, getState}, {params}) => {
              const state = getState()

              if (state.user.user) {
                return dispatch(actions.getMyViewings())
              }
            }
          },
          {
            path: '*',
            component: ErrorPage,
            name: 'error-page',
            exact: true,
            fetchData: ({dispatch}) => {
              return dispatch(actions.setPageStatus(404))
            }
          },
        ],
      },
    ],
  },
]

export default routes
