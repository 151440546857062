const defaultState = {
  amenities: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_AMENITIES_FULFILLED': {
      state = {
        ...state,
        amenities: [...action.payload],
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
