import React from 'react'
import { hydrate } from 'react-dom'
import Loadable from 'react-loadable'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import Cookies from 'universal-cookie'
// import history from './helpers/createHistory'
import {createBrowserHistory } from 'history'

import DataLoader from './DataLoader'
import configureStore from './store'

const root = document.getElementById('app');

const history = createBrowserHistory();
const store = configureStore(window.__PRELOADED_STATE__, history)

global.cookies = new Cookies()

if (module.hot) {
  module.hot.accept('./pages/App', () => {
    // const NewApp = require('./pages/App').default;
    // render(NewApp);
    // const history = createBrowserHistory()

    <Provider store={newStore}>
      <ConnectedRouter history={ history }>
        <DataLoader />
      </ConnectedRouter>
  </Provider>
  });
}

function render() {
  Loadable.preloadReady().then(() => {
    hydrate(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <DataLoader />
        </ConnectedRouter>
      </Provider>,
      root,
    );
  });
}

window.main = () => {
  render();
};
