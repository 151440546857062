import sumBy from 'lodash/sumBy'

const defaultState = {
  buildings: [],
  buildingsFetching: false,
  buildingsTotalItems: null,
  allBuildings: [],
  allBuildingsFetching: false,
  myBuildings: [],
  myBuildingsFetching: false,
  building: null,
  buildingFetching: false,
  editBuilding: null,
  similarBuildings: [],
  filters: {
    page: 1,
    building_types: [],
    sort_by: null,
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_BUILDING_PENDING': {
      state = {
        ...state,
        building: null,
        buildingFetching: true,
      }
      break
    }
    case 'GET_BUILDING_FULFILLED': {
      let building = action.payload

      const totalBuildingReviews = building.building_reviews.length

      building.amenitiesRating = sumBy(building.building_reviews, 'amenities_rating') / totalBuildingReviews
      building.locationRating = sumBy(building.building_reviews, 'location_rating') / totalBuildingReviews
      building.managementRating = sumBy(building.building_reviews, 'management_rating') / totalBuildingReviews
      building.safetyRating = sumBy(building.building_reviews, 'safety_rating') / totalBuildingReviews
      building.valueRating = sumBy(building.building_reviews, 'value_rating') / totalBuildingReviews
      building.neighborsRating = sumBy(building.building_reviews, 'neighborhs_rating') / totalBuildingReviews
      building.overallRating = (building.amenitiesRating + building.locationRating + building.managementRating + building.safetyRating + building.valueRating + building.neighborsRating) / 6

      state = {
        ...state,
        building,
        buildingFetching: false,
      }
      break
    }
    case 'GET_BUILDING_REJECTED': {
      state = {
        ...state,
        building: null,
        buildingFetching: false,
      }
      break
    }
    case 'CREATE_BUILDING_REVIEW_PENDING': {
      state = {
        ...state,
        createBuildingReviewPending: true,
      }
      break
    }
    case 'CREATE_BUILDING_REVIEW_FULFILLED': {
      state = {
        ...state,
        createBuildingReviewPending: false,
      }

      swal('SUCCESS', 'You‘ve successfully submitted your building review for approval!', 'success')

      break
    }
    case 'CREATE_BUILDING_REVIEW_REJECTED': {
      state = {
        ...state,
        createBuildingReviewPending: false,
      }

      if (err && err.data && err.data.error) {
        swal('ERROR', err.data.error, 'error')
      } else {
        swal('ERROR', 'Something went wrong. Please try again later!', 'error')
      }

      break
    }
    case 'GET_BROWSE_BUILDINGS_PENDING': {
      state = {
        ...state,
        buildings: [],
        buildingsFetching: true,
        buildingsTotalItems: null,
        filters: action.meta ? action.meta.filters : defaultState.filters,
      }
      break
    }
    case 'GET_BROWSE_BUILDINGS_FULFILLED': {
      state = {
        ...state,
        buildings: action.payload.buildings,
        buildingsFetching: false,
        buildingsTotalItems: action.payload.total_items,
      }
      break
    }
    case 'GET_BROWSE_BUILDINGS_REJECTED': {
      state = {
        ...state,
        buildings: [],
        buildingsFetching: false,
        buildingsTotalItems: null,
      }
      break
    }
    case 'RESET_BROWSE_BUILDINGS': {
      state = {
        ...state,
        buildings: [],
        buildingsFetching: false,
        buildingsTotalItems: null,
        filters: {...defaultState.filters},
      }
      break
    }
    case 'GET_ALL_BUILDINGS_PENDING': {
      state = {
        ...state,
        allBuildings: [],
        allBuildingsFetching: true,
      }
      break
    }
    case 'GET_ALL_BUILDINGS_FULFILLED': {
      state = {
        ...state,
        allBuildings: action.payload,
        allBuildingsFetching: false,
      }
      break
    }
    case 'GET_ALL_BUILDINGS_REJECTED': {
      state = {
        ...state,
        allBuildings: [],
        allBuildingsFetching: false,
      }
      break
    }
    case 'GET_BUILDINGS_PENDING': {
      state = {
        ...state,
        buildings: [],
        buildingsFetching: true,
        buildingsTotalItems: null,
      }
      break
    }
    case 'GET_BUILDINGS_FULFILLED': {
      state = {
        ...state,
        buildings: action.payload.buildings,
        buildingsFetching: false,
        buildingsTotalItems: action.payload.total_items,
      }
      break
    }
    case 'GET_BUILDINGS_REJECTED': {
      state = {
        ...state,
        buildings: [],
        buildingsFetching: false,
        buildingsTotalItems: null,
      }
      break
    }
    case 'GET_MY_BUILDINGS_PENDING': {
      state = {
        ...state,
        myBuildings: [],
        myBuildingsFetching: true,
      }
      break
    }
    case 'GET_MY_BUILDINGS_FULFILLED': {
      state = {
        ...state,
        myBuildings: action.payload,
        myBuildingsFetching: false,
      }
      break
    }
    case 'GET_MY_BUILDINGS_REJECTED': {
      state = {
        ...state,
        myBuildingsFetching: false,
      }
      break
    }
    case 'GET_EDIT_BUILDING_PENDING': {
      state = {
        ...state,
        editBuilding: null,
      }
      break
    }
    case 'GET_EDIT_BUILDING_FULFILLED': {
      state = {
        ...state,
        editBuilding: action.payload,
      }
      break
    }
    case 'GET_EDIT_BUILDING_REJECTED': {
      state = {
        ...state,
        editBuilding: action.payload,
      }
      break
    }
    case 'GET_SIMILAR_BUILDINGS_FULFILLED': {
      state = {
        ...state,
        similarBuildings: [...action.payload.buildings],
      }

      break
    }
    case 'RESET_BUILDING': {
      state = {
        ...state,
        building: null,
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
