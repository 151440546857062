import orderBy from 'lodash/orderBy'

const defaultState = {
  neighborhoods: [],
  groupedNeighborhoods: [],
  selectedNeighborhoods: [],
  similarNeighborhoods: [],
  neighborhood: null,
  fetchingAll: false,
  fetchingOne: false,
  fetchingNeighborhoodLink: false,
  neighborhoodLink: null,
}

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case 'GET_ALL_GUIDE_PENDING': {
      state = {
        ...state,
        neighborhoods: [],
        fetchingAll: true,
      }
      break
    }
    case 'GET_ALL_GUIDE_FULFILLED': {
      state = {
        ...state,
        neighborhoods: [...action.payload],
        fetchingAll: false,
      }
      break
    }
    case 'GET_SIMILAR_NEIGHBORHOODS_PENDING': {
      state = {
        ...state,
        similarNeighborhoods: [],
        fetchingSimilarNeighborhoods: true,
      }
      break
    }
    case 'GET_SIMILAR_NEIGHBORHOODS_FULFILLED': {
      state = {
        ...state,
        similarNeighborhoods: [...action.payload],
        fetchingSimilarNeighborhoods: false,
      }
      break
    }
    case 'GET_SIMILAR_NEIGHBORHOODS_REJECTED': {
      state = {
        ...state,
        similarNeighborhoods: [],
        fetchingSimilarNeighborhoods: false,
      }
      break
    }
    case 'RESET_SIMILAR_NEIGHBORHOODS': {
      state = {
        ...state,
        similarNeighborhoods: [],
      }
      break
    }
    case 'GET_NEIGHBORHOOD_LINK_PENDING': {
      state = {
        ...state,
        neighborhoodLink: null,
        fetchingNeighborhoodLink: true,
      }
      break
    }
    case 'GET_NEIGHBORHOOD_LINK_FULFILLED': {
      state = {
        ...state,
        neighborhoodLink: action.payload,
        fetchingNeighborhoodLink: false,
      }
      break
    }
    case 'GET_NEIGHBORHOOD_LINK_REJECTED': {
      state = {
        ...state,
        neighborhoodLink: null,
        fetchingNeighborhoodLink: false,
      }
      break
    }
    case 'GET_ALL_GROUPED_NEIGHBORHOODS_FULFILLED': {
      let neighborhoods = action.payload

      neighborhoods.forEach(sublocality => {
        sublocality.regions.forEach(region => {
          region.neighbourhoods = orderBy(region.neighbourhoods, ['has_subneighbourhoods'], ['desc'])
          region.neighbourhoods.forEach(neighborhood => {
            neighborhood.coordinates = neighborhood.coordinates.map(c => [parseFloat(c[1]), parseFloat(c[0])])
          })
        })
      })

      state = {
        ...state,
        groupedNeighborhoods: [...neighborhoods],
      }
      break
    }
    case 'GET_BROWSE_RENTALS_PENDING': {
      if (action.meta && action.meta.filters) {
        state = {
          ...state,
          selectedNeighborhoods: [...action.meta.filters.filter_by.neighbourhood],
        }
      }
      break
    }
    case 'GET_BROWSE_SALES_PENDING': {
      if (action.meta && action.meta.filters) {
        state = {
          ...state,
          selectedNeighborhoods: [...action.meta.filters.filter_by.neighbourhood],
        }
      }
      break
    }
    case 'SET_SELECTED_NEIGHBORHOODS': {
      state = {
        ...state,
        selectedNeighborhoods: [...action.payload],
      }
      break
    }
    case 'RESET_BROWSE_SALES':
    case 'RESET_BROWSE_RENTALS':
    case 'RESET_SELECTED_NEIGHBORHOODS': {
      state = {
        ...state,
        selectedNeighborhoods: [],
      }
      break
    }
    case 'GET_ONE_GUIDE_PENDING': {
      state = {
        ...state,
        neighborhood: null,
        fetchingOne: true,
      }
      break
    }
    case 'GET_ONE_GUIDE_FULFILLED': {
      state = {
        ...state,
        neighborhood: action.payload ? {...action.payload} : null,
        fetchingOne: false,
      }
      break
    }
    case 'GET_ONE_GUIDE_REJECTED': {
      state = {
        ...state,
        neighborhood: null,
        fetchingOne: false,
      }
      break
    }
    case 'RESET_ONE_GUIDE': {
      state = {
        ...state,
        neighborhood: null,
      }
      break
    }
    case 'GET_ONE_GUIDE_PREVIEW_PENDING': {
      state = {
        ...state,
        neighborhoodPreview: null,
        fetchingOnePreview: true,
      }
      break
    }
    case 'GET_ONE_GUIDE_PREVIEW_FULFILLED': {
      state = {
        ...state,
        neighborhoodPreview: action.payload ? {...action.payload} : null,
        fetchingOnePreview: false,
      }
      break
    }
    case 'GET_ONE_GUIDE_PREVIEW_REJECTED': {
      state = {
        ...state,
        neighborhoodPreview: null,
        fetchingOnePreview: false,
      }
      break
    }
    case 'RESET_ONE_GUIDE_PREVIEW': {
      state = {
        ...state,
        neighborhoodPreview: null,
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
