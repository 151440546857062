/* global swal */

import viewingService from '../services/viewingService'

export const createViewing = data => {
  return {
    type: 'CREATE_VIEWING',
    payload: viewingService.createViewing(data),
  }
}

export const createViewingReview = data => {
  return dispatch => {
    dispatch({
      type: 'CREATE_VIEWING_REVIEW_PENDING',
    })

    viewingService.createViewingReview(data)
    .then(() => {
      dispatch({
        type: 'CREATE_VIEWING_REVIEW_FULFILLED',
      })

      swal({
        title: 'SUCCESS',
        html: `<div>
          <div class="mb-40 mt-10">
            Thanks for the review!
          </div>
          <div class="swal-balance">
            <div class="swal-balance-row">
              <div class="swal-balance-label">Earlier Balance</div>
              <div class="swal-balance-value">200 PCC</div>
            </div>
            <div class="swal-balance-row">
              <div class="swal-balance-label">Review Reward</div>
              <div class="swal-balance-value accent">+100 PCC</div>
            </div>
            <div class="swal-balance-row swal-balance-row-full">
              <div class="swal-balance-label">New Balance</div>
              <div class="swal-balance-value">300 PCC</div>
            </div>
          </div>
        </div>`,
        type: 'success',
      })

      viewingService.getMyViewings()
      .then(data => {
        dispatch({
          type: 'GET_MY_VIEWINGS_FULFILLED',
          payload: data,
        })
      })
    })
    .catch(err => {
      dispatch({
        type: 'CREATE_VIEWING_REVIEW_REJECTED',
        payload: err,
      })

      if (err.data && err.data.error) {
        swal('ERROR', err.data.error, 'error')
      } else {
        swal('ERROR', 'Something went wrong. Please try again later!', 'error')
      }
    })
  }
}

export const setCurrentViewingReview = viewingId => {
  return {
    type: 'SET_CURRENT_VIEWING_REVIEW',
    payload: viewingId,
  }
}

export const confirmViewing = data => {
  viewingService.confirmViewing(data)
  .then(() => {
    swal('SUCCESS', 'You’ve accepted the viewing request.', 'success', {
      closeOnClickOutside: false,
    })
  })
  .catch(err => {
    if (err.data && err.data.error) {
      swal('ERROR', err.data.error, 'error', {
        closeOnClickOutside: false,
      })
    } else {
      swal('ERROR', 'Something went wrong with your viewing confirmation. Please try again!', 'error', {
        closeOnClickOutside: false,
      })
    }
  })

  return {
    type: 'CONFIRM_VIEWING',
  }
}

export const getMyViewings = () => {
  return {
    type: 'GET_MY_VIEWINGS',
    payload: viewingService.getMyViewings(),
  }
}
