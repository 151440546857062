import axios from 'axios'

import { RequestService } from '../store'

import * as constants from '../helpers/constants'

const CancelToken = axios.CancelToken
let cancel

class BuildingService {
  cancelGetBrowseBuildings() {
    if (cancel) {
      cancel()
    }
  }

  getBrowseBuildings(filters, noFilters) {
    if (cancel) {
      cancel()
    }

    return RequestService.get('/buildings', noFilters ? null : {
        total_items: constants.browseBuildingsPerPage,
        page: (filters && filters.page) || 1,
      }, null, {
        cancelToken: new CancelToken(function executor (c) {
          cancel = c;
        }),
      },
    )
  }

  getBuildings() {
    return RequestService.get('/buildings', null)
  }

  getAllBuildings() {
    return RequestService.get('/buildings/all', null)
  }

  getBuilding(buildingId) {
    return RequestService.get('/buildings/' + buildingId, null)
  }

  removeBuilding(buildingId) {
    return RequestService.delete('/buildings/' + buildingId, null)
  }

  getSimilarBuildings(buildingSlug) {
    return RequestService.get('/buildings/' + buildingSlug + '/similar')
  }

  createBuildingReview(data) {
    return RequestService.post('/building_reviews', null, data)
  }

  saveBuilding(data) {
    return RequestService.post('/buildings', null, data)
  }

  updateBuilding(data) {
    return RequestService.put('/buildings/' + data.id, null, data)
  }

  getMyBuildings() {
    return RequestService.get('/buildings/edit')
  }
}

export default new BuildingService()
