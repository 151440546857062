import neighborhoodService from '../services/neighborhoodService'

export const getAllNeighborhoodGuide = params => {
  return {
    type: 'GET_ALL_GUIDE',
    payload: neighborhoodService.getAllNeighborhoodGuide(params)
  }
}

export const getAllGroupedNeighborhoods = () => {
  return {
    type: 'GET_ALL_GROUPED_NEIGHBORHOODS',
    payload: neighborhoodService.getAllGroupedNeighborhoods()
  }
}

export const setSelectedNeighborhoods = selectedNeighborhoods => {
  return {
    type: 'SET_SELECTED_NEIGHBORHOODS',
    payload: selectedNeighborhoods,
  }
}

export const getOneNeighborhoodGuide = name => {
  return {
    type: 'GET_ONE_GUIDE',
    payload: neighborhoodService.getOneNeighborhoodGuide(name)
  }
}

export const getOneNeighborhoodGuidePreview = name => {
  return {
    type: 'GET_ONE_GUIDE_PREVIEW',
    payload: neighborhoodService.getOneNeighborhoodGuide(name, {preview: true})
  }
}

export const getSimilarNeighborhoods = (slug, count) => {
  return {
    type: 'GET_SIMILAR_NEIGHBORHOODS',
    payload: neighborhoodService.getSimilarNeighborhoods(slug, count)
  }
}

export const resetSimilarNeighborhoods = () => {
  return {
    type: 'RESET_SIMILAR_NEIGHBORHOODS',
  }
}

export const resetNeighborhood = name => {
  return {
    type: 'RESET_ONE_GUIDE',
  }
}

export const resetNeighborhoodPreview = name => {
  return {
    type: 'RESET_ONE_GUIDE_PREVIEW',
  }
}

export const getNeighborhoodLink = slug => {
  return {
    type: 'GET_NEIGHBORHOOD_LINK',
    payload: neighborhoodService.getNeighborhoodLink(slug),
  }
}

export const resetNeighborhoodLink = slug => {
  return {
    type: 'GET_NEIGHBORHOOD_LINK_FULFILLED',
    payload: null,
  }
}
