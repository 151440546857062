const defaultState = {
  subwayLines: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_SUBWAY_LINES_FULFILLED': {
      state = {
        ...state,
        subwayLines: [...action.payload],
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
