/* global swal */

import { push } from 'react-router-redux'

import { RequestService } from '../store'
import UserService from '../services/userService'

export const getUser = () => {
  return {
    type: 'GET_USER',
    payload: RequestService.getUser(),
  }
}

export const getProUserSuggestions = value => {
  return {
    type: 'GET_PRO_USER_SUGGESTIONS',
    payload: UserService.getProUserSuggestions(value),
  }
}

export const resetProUserSuggestions = () => {
  return {
    type: 'GET_PRO_USER_SUGGESTIONS',
    payload: [],
  }
}

export const addToNewsletter = data => {
  return {
    type: 'ADD_TO_NEWSLETTER',
    payload: UserService.addToNewsletter(data),
  }
}

export const setInitialUser = () => {
  const user = RequestService.getUser()

  return {
    type: 'SET_USER',
    payload: user,
  }
}

export const addCreditCard = data => {
  return dispatch => {
    dispatch({
      type: 'ADD_CREDIT_CARD_PENDING',
    })

    UserService.addCreditCard(data)
    .then(res => {
      const user = RequestService.getUser()
      user.credit_cards = res
      RequestService.setUser(user)

      dispatch({
        type: 'SET_USER',
        payload: user,
      })

      dispatch({
        type: 'ADD_CREDIT_CARD_FULFILLED',
        payload: user,
      })
    }, (err) => {
      swal('ERROR', 'Something went wrong. Please try again!', 'error')
    })
  }
}

export const removeCreditCard = data => {
  return dispatch => {
    dispatch({
      type: 'REMOVE_CREDIT_CARD_PENDING',
    })

    UserService.removeCreditCard(data)
    .then(res => {
      const user = RequestService.getUser()
      user.credit_cards = res
      RequestService.setUser(user)

      dispatch({
        type: 'SET_USER',
        payload: user,
      })

      dispatch({
        type: 'REMOVE_CREDIT_CARD_FULFILLED',
        payload: user,
      })
    }, () => {
      swal('ERROR', 'Something went wrong. Please try again!', 'error')
    })
  }
}

export const getUserData = userId => {
  return {
    type: 'GET_USER_DATA',
    payload: UserService.getUser(userId),
  }
}

export const getRoommates = () => {
  return {
    type: 'GET_ROOMMATES',
    payload: UserService.getRoommates(),
  }
}

export const getRoommate = userId => {
  return {
    type: 'GET_ROOMMATE',
    payload: UserService.getRoommate(userId),
  }
}

export const resetRoommate = userId => {
  return {
    type: 'GET_ROOMMATE_FULFILLED',
    payload: null,
  }
}

export const uploadUserImage = image => {
  return (dispatch) => {
    dispatch({
      type: 'UPLOAD_USER_IMAGE_PENDING',
    })

    UserService.uploadUserImage(image)
    .then(res => {
      const user = RequestService.getUser()
      user.picture = res.path
      RequestService.setUser(user)

      dispatch({
        type: 'SET_USER',
        payload: user,
      })

      dispatch({
        type: 'UPLOAD_USER_IMAGE_FULFILLED',
        payload: res.path,
      })
    })
  }
}

export const updateUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_USER_PENDING',
    })

    UserService.updateUser(data)
    .then((user) => {

      RequestService.setUser(user)

      dispatch({
        type: 'SET_USER',
        payload: user,
      })

      dispatch({
        type: 'UPDATE_USER_FULFILLED',
        payload: user,
      })

      dispatch(push('/coliving'))
    })
  }
}

export const changePassword = (data) => {
  return {
    type: 'CHANGE_PASSWORD',
    payload: UserService.changePassword(data),
  }
}

export const tryLogin = data => {
  return {
    type: 'TRY_LOGIN',
    payload: UserService.login(data),
  }
}

export const login = data => {
  return {
    type: 'LOGIN',
    payload: UserService.login(data),
  }
}

export const signUp = data => {
  return {
    type: 'SIGN_UP',
    payload: UserService.signUp(data),
  }
}

export const sendForgotPassword = data => {
  return {
    type: 'SEND_FORGOT_PASSWORD',
    payload: UserService.sendForgotPassword(data),
  }
}

export const setNewPassword = data => {
  return dispatch => {
    dispatch({
      type: 'SET_NEW_PASSWORD_PENDING',
    })

    UserService.setNewPassword(data)
    .then(() => {
      dispatch({
        type: 'SET_NEW_PASSWORD_FULFILLED',
      })
      dispatch(push('/'))
      dispatch({
        type: 'SET_LOGIN_MODAL_VISIBILITY',
        payload: true,
      })
    })
    .catch(err => {
      dispatch({
        type: 'SET_NEW_PASSWORD_REJECTED',
      })
    })
  }
}

export const initialSetup = (data, conversationId) => {
  return dispatch => {
    dispatch({
      type: 'INITIAL_SETUP_PENDING',
    })

    UserService.initialSetup(data)
    .then(response => {
      dispatch({
        type: 'INITIAL_SETUP_FULFILLED',
      })

      const user = response

      RequestService.setUser(user)

      dispatch({
        type: 'SET_USER',
        payload: user,
      })

      if (conversationId) {
        dispatch(push('/conversation/' + conversationId))
      } else {
        dispatch(push('/'))
      }
    })
    .catch(err => {
      dispatch({
        type: 'INITIAL_SETUP_REJECTED',
      })
    })
  }
}

// export const getFacebookUser = (data) => {
//   return {
//     type: 'GET_FACEBOOK_USER',
//     payload: UserService.getFacebookUser(data)
//   }
// }

// export const unsetFacebookUser = () => {
//   return {
//     type: 'UNSET_FACEBOOK_USER'
//   }
// }

export const logout = (data) => {
  return {
    type: 'LOGOUT',
  }
}


// export const resetPassword = (data) => {
//   return {
//     type: 'RESET_PASSWORD',
//     payload: UserService.resetPassword(data)
//   }
// }

// export const markListingAsFavorite = (id) => {
//   return (dispatch) => {
//     UserService.markListingAsFavorite(id)
//     .then(() => {
//       dispatch({
//         type: 'MARK_LISTING_AS_FAVORITE_FULFILLED',
//         payload: id
//       })
//     })
//   }
// }

// export const getFavoriteListings = () => {
//   return {
//     type: 'GET_FAVORITE_LISTINGS',
//     payload: UserService.getFavoriteListings()
//   }
// }

// export const getFavoriteSales = () => {
//   return {
//     type: 'GET_FAVORITE_SALES',
//     payload: UserService.getFavoriteSales()
//   }
// }

// export const markSaleAsFavorite = (id) => {
//   return (dispatch) => {
//     UserService.markSaleAsFavorite(id)
//     .then(() => {
//       dispatch({
//         type: 'MARK_SALE_AS_FAVORITE_FULFILLED',
//         payload: id
//       })
//     })
//   }
// }