import { RequestService } from '../store'

class ViewingService {
  getMyViewings() {
    return RequestService.get('/viewings')
  }

  createViewing(data) {
    return RequestService.post('/viewings', null, data)
  }

  createViewingReview(data) {
    return RequestService.put('/viewings/' + data.id + '/review', null, data.review)
  }

  confirmViewing(data) {
    return RequestService.put('/viewings/' + data.viewing_id, null, {
      status: data.status,
    }, {
      headers: {
        'x-access-token': data.token,
      }
    })
  }
}

export default new ViewingService()
