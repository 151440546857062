/* global cookies, __ENV__ */

import axios from 'axios'

class RequestService {
  constructor(store, history) {
    this.store = store

    let apiHost = 'http://localhost:3020'

    if (__ENV__ === 'staging') {
      apiHost = 'http://dev.propertyclub.nyc'
    } else if (__ENV__ === 'production') {
      apiHost = 'https://propertyclub.nyc'
    }

    this.user = {}
    this.config = {
      apiHost:                    apiHost,
      apiBase:                    '/api',
      apiVersion:                 '',

      globalConfig: {
        headers: {
          'Content-Type':         'application/json',
          'Accept':               'application/json',
          'Authorization':        'Basic bGVhc2Vob3A6QW5kcmV3MTgy',
        }
      }
    }
  }

  /*
    Sets and saves the user
    */
  setUser(data) {
    if (data) {
      const user = {
        id: data.id,
        access_token: data.access_token,
        first_name: data.first_name,
        last_name: data.last_name,
        is_pro: data.is_pro,
        picture: data.picture,
        credit_cards: data.credit_cards,
        building_role: data.building_role,
        pcc: data.pcc,
        roommate_access: data.roommate_access,
        is_roommate: data.is_roommate,
        limit: data.limit,
      }
      this.user = user
      cookies.set('user', user)

      return user
    } else {
      this.user = null
      cookies.remove('user')
    }
  }

  /*
    Returns user from cookie
    */
  getUser() {
    const user = cookies.get('user')
    this.user = user

    return user
  }

  get(url, params, opts) {
    // added param if user is pro
    if (this.user && this.user.is_pro) {
      params = params || {}
      params.pro_user = true
    }

    if (params) {
      url = this._addParamsToURL(url, params)
    }

    return this._request(url, 'GET', opts)
  }

  post(url, params, body, opts) {
    // added param if user is pro
    if (this.user && this.user.is_pro) {
      params = params || {}
      params.pro_user = true
    }

    if (params) {
      url = this._addParamsToURL(url, params)
    }

    return this._request(url, 'POST', body || {}, opts)
  }

  delete(url, params, body, opts) {
    // added param if user is pro
    if (this.user && this.user.is_pro) {
      params = params || {}
      params.pro_user = true
    }

    if (params) {
      url = this._addParamsToURL(url, params)
    }

    return this._request(url, 'DELETE', body, opts)
  }

  put(url, params, body, opts) {
    // added param if user is pro
    if (this.user && this.user.is_pro) {
      params = params || {}
      params.pro_user = true
    }

    if (params) {
      url = this._addParamsToURL(url, params)
    }

    return this._request(url, 'PUT', body, opts)
  }

  multipart(url, file) {
    url = this._getApiPath() + url

    const data = new FormData();
    data.append('file', file)

    const options = {
      method: 'POST',
      headers: {
        ...this._composeHeaders(),
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    }

    return new Promise((resolve, reject) => {
      axios(url, options)
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err.response)
      })
    })
  }

  _addParamsToURL(url, params) {
    url += '?'
    let first = true

    Object.keys(params).forEach(param => {
      let value = params[param]
      if (!first) {
        url += '&'
      }
      first = false
      url += param + '=' + value
    })

    return url
  }

  _request(url, method, body, opts) {
    url = this._getApiPath() + url
    opts = opts || {}

    const options = {
      method: method,
      data: JSON.stringify(body),
      ...opts,
      headers: {
        ...this._composeHeaders(),
        ...opts.headers,
      },
    }

    return new Promise((resolve, reject) => {
      axios(url, options)
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          reject({
            isCancel: true,
          })
        } else {
          reject(err.response)
        }
      })
    })
  }

  _getApiPath() {
    return this.config.apiHost + this.config.apiBase + this.config.apiVersion
  }

  _composeHeaders() {
    return Object.assign({}, this._getCurrentAuthHeaders(), this.config.globalConfig.headers)
  }

  _getCurrentAuthHeaders() {
    if (this.user && this.user.access_token) {
      return {
        'x-access-token': this.user.access_token,
      }
    }
  }
}

export default RequestService
