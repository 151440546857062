import App from './pages/App'

import ErrorPage from './pages/ErrorPage'
import IsLoggedIn from './components/IsLoggedIn'

const routes = [
  {
    component: App,
    routes: [
      {
        component: IsLoggedIn,
        isLoggedIn: true,
        routes: [
          {
            path: '*',
            component: ErrorPage,
            name: 'error-page',
            exact: true,
          },
        ],
      },
    ],
  },
]

export default routes
