const defaultState = {
  broker: null,
  rentals: [],
  sales: [],
  totalRentals: 0,
  totalSales: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_BROKER_FULFILLED': {
      state = {
        ...state,
        broker: action.payload,
      }
      break
    }
    case 'GET_BROKER_RENTALS_FULFILLED': {
      state = {
        ...state,
        rentals: action.payload.lease_details || [],
        totalRentals: action.payload.total_items || 0,
      }
      break
    }
    case 'GET_BROKER_SALES_FULFILLED': {
      state = {
        ...state,
        sales: action.payload || [],
        totalSales: action.payload[0] ? action.payload[0].total_items : 0,
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
