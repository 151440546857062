import qs from 'qs'
import { push } from 'react-router-redux'

import { RequestService } from '../store'
import rentalService from '../services/rentalService'
import userService from '../services/userService'

export const getRental = rentalId => {
  return {
    type: 'GET_RENTAL',
    payload: rentalService.getListing(rentalId, {
      with_similar_neighborhoods: true,
    }),
  }
}

export const removeRental = rentalId => {
  return {
    type: 'REMOVE_RENTAL',
    payload: rentalService.removeListing(rentalId),
  }
}

export const extendRental = data => {
  return {
    type: 'EXTEND_RENTAL',
    payload: rentalService.extendListing(data),
  }
}

export const getEditRental = rentalId => {
  return {
    type: 'GET_EDIT_RENTAL',
    payload: rentalService.getListing(rentalId),
  }
}

export const resetEditRental = () => {
  return {
    type: 'GET_EDIT_RENTAL_FULFILLED',
    payload: null,
  }
}

export const saveRental = rental => {
  return (dispatch => {
    dispatch({
      type: 'SAVE_RENTAL_PENDING',
    })

    rentalService.saveRental(rental).then(data => {
      dispatch({
        type: 'SAVE_RENTAL_FULFILLED',
      })

      if (rental.home_type === 'single_room') {
        dispatch(push('/my-rooms'))
      } else {
        dispatch(push('/my-listings'))
      }

      let user = RequestService.getUser()
      user.pcc = data.new_pcc_amount
      RequestService.setUser(user)

      dispatch({
        type: 'SET_USER',
        payload: user,
      })
    }, err => {
      dispatch({
        type: 'SAVE_RENTAL_REJECTED',
        payload: err,
      })
    })
  })
}

export const updateRental = rental => {
  return (dispatch => {
    dispatch({
      type: 'UPDATE_RENTAL_PENDING',
    })

    rentalService.updateRental(rental).then(data => {
      dispatch({
        type: 'UPDATE_RENTAL_FULFILLED',
      })

      if (rental.home_type === 'single_room') {
        dispatch(push('/my-rooms'))
      } else {
        dispatch(push('/my-listings'))
      }
    }, err => {
      dispatch({
        type: 'UPDATE_RENTAL_REJECTED',
        payload: err,
      })
    })
  })
}

export const resetRental = () => {
  return {
    type: 'RESET_RENTAL',
  }
}

export const resetBrowseRentals = () => {
  rentalService.cancelGetBrowseRentals()

  return {
    type: 'RESET_BROWSE_RENTALS',
  }
}

export const pendingBrowseRentals = () => {
  return {
    type: 'GET_BROWSE_RENTALS_PENDING',
  }
}

export const toggleFavoriteRental = (user, rental) => {
  if (user) {
    return {
      type: 'FAVORITE_RENTAL',
      payload: userService.favorite(rental.id, null),
      meta: {
        rental,
      },
    }
  } else {
    return {
      type: 'SET_LOGIN_MODAL_VISIBILITY',
      payload: true,
    }
  }
}

export const getFavoriteRentals = userId => {
  return {
    type: 'GET_FAVORITE_RENTALS',
    payload: rentalService.getFavoriteRentals(userId),
  }
}

export const resetFavoriteRentals = () => {
  return {
    type: 'RESET_FAVORITE_RENTALS',
  }
}

export const getHomepageRentals = filters => {
  return {
    type: 'GET_HOMEPAGE_RENTALS',
    payload: rentalService.getHomepageListings(filters),
  }
}

export const getRoomsPreviewListings = filters => {
  return {
    type: 'GET_ROOMS_PREVIEW_LISTINGS',
    payload: rentalService.getRoomsPreviewListings(filters),
  }
}

const getFiltersFromUrl = (queryString, extraFilters) => {
  const queryFilters = qs.parse(decodeURI(queryString), {
    decoder: str => {
      const floatStr = parseFloat(str)

      if (!isNaN(floatStr) && str === floatStr) {
        str = floatStr
      } else if (str === 'true') {
        str = true
      } else if (str === 'false') {
        str = false
      }

      return str
    },
    allowDots: true,
    arrayLimit: 200,
  }) || {}

  const filterBy = queryFilters.filter_by || {}

  return {
    page: queryFilters.page || 1,
    filter_by: {
      address: filterBy.address || undefined,
      amenities: (filterBy.amenities && filterBy.amenities.map(str => parseInt(str, 10))) || [],
      bathrooms: (filterBy.bathrooms && filterBy.bathrooms.map(str => parseInt(str, 10))) || [],
      bathroom: (filterBy.bathroom && parseInt(filterBy.bathroom, 10)) || undefined,
      bbox: filterBy.bbox || {},
      bedrooms: (filterBy.bedrooms && filterBy.bedrooms.map(str => parseInt(str, 10))) || [],
      bedroom: (filterBy.bedroom && parseInt(filterBy.bedroom, 10)) || undefined,
      exclusive: filterBy.exclusive || undefined,
      neighbourhood: filterBy.neighbourhood || [],
      listed_by: filterBy.listed_by || undefined,
      pet_policy: filterBy.pet_policy || undefined,
      polygon: filterBy.polygon || [],
      price: filterBy.price || {},
      subway_lines: filterBy.subway_lines || [],
      zones: filterBy.zones || undefined,
      zone_slug: filterBy.zone_slug || undefined,
      penthouses: filterBy.penthouses || undefined,
      amenities_slug: filterBy.amenities_slug || [],
      home_type: 'entire_place',
      ...extraFilters,
    },
    sort_by: queryFilters.sort_by || undefined,
  }
}

export const getBrowseRentals = (queryString, extraFilters, isLimitedPage) => {
  const filters = getFiltersFromUrl(queryString, extraFilters)

  if (isLimitedPage && filters.page > 10) {
    return {
      type: 'SET_PAGE_STATUS',
      payload: 404,
    }
  }

  return {
    type: 'GET_BROWSE_RENTALS',
    payload: rentalService.getBrowseRentals(filters),
    meta: {
      filters,
    },
  }
}

export const getRentalPriceStats = (queryString, extraFilters) => {
  let filters = getFiltersFromUrl(queryString, extraFilters)

  filters.page = undefined
  filters.filter_by.price = {}

  return {
    type: 'GET_RENTAL_PRICE_STATS',
    payload: rentalService.getRentalPriceStats(filters),
  }
}

export const getRentalCount = (queryString, extraFilters) => {
  let filters = getFiltersFromUrl(queryString, extraFilters)

  filters.page = undefined

  return {
    type: 'GET_RENTAL_COUNT',
    payload: rentalService.getRentalCount(filters),
  }
}

export const getSimilarRentals = rentalId => {
  return {
    type: 'GET_SIMILAR_RENTALS',
    payload: rentalService.getSimilarRentals(rentalId),
  }
}

export const openMoreRentalFilters = () => {
  return {
    type: 'OPEN_MORE_RENTAL_FILTERS',
  }
}

export const closeMoreRentalFilters = () => {
  return {
    type: 'CLOSE_MORE_RENTAL_FILTERS',
  }
}

export const setMoreRentalFilters = values => {
  return {
    type: 'SET_MORE_RENTAL_FILTERS',
    payload: values,
  }
}

export const getMyRentals = (loggedUser, loadedListings, homeType) => {
  if (loggedUser.is_pro) {
    return {
      type: 'GET_MY_PRO_RENTALS',
      payload: rentalService.getMyProRentals(loadedListings, homeType),
    }
  } else {
    return {
      type: 'GET_MY_RENTALS',
      payload: rentalService.getMyRentals(loggedUser, homeType),
    }
  }
}

export const resetMyRentals = () => {
  return {
    type: 'RESET_MY_RENTALS',
  }
}

export const toggleUnpublishRental = (rental, unpublished) => {
  return dispatch => {
    dispatch({
      type: 'SET_UNPUBLISH_RENTAL',
      payload: {
        id: rental.id,
        unpublished: unpublished,
      },
    })

    rentalService.toggleUnpublishRental(rental, unpublished).then(() => {

    }, err => {
      dispatch({
        type: 'SET_UNPUBLISH_RENTAL_REJECTED',
        payload: err,
      })
    })
  }
}
