import React from 'react'
import { NavLink } from 'react-router-dom'

export default ({user, unreadConversations, logout}) => (
  <div>
    {
      user.is_pro && user.limit ?
        <NavLink className="link" to="/new-listing" activeClassName="active" exact={true}>
          <i className="nc-icon-mini design_pen-01"></i>
          New Listing
        </NavLink>
        : null
    }
    <NavLink className="link" to="/inbox" activeClassName="active" exact={true}>
      <i className="nc-icon-mini ui-1_email-83"></i>
      {
        !!unreadConversations ?
          <span className="notification">{unreadConversations}</span>
          : null
      }
      Inbox
    </NavLink>
    <NavLink className="link" to="/my-listings" activeClassName="active" exact={true}>
      <i className="nc-icon-mini ui-2_paragraph"></i>
      My Listings
    </NavLink>
    {
      user.is_pro && user.building_role ?
        <NavLink className="link" to="/my-buildings" activeClassName="active" exact={true}>
          <i className="nc-icon-mini ui-2_paragraph"></i>
          My Buildings
        </NavLink>
        : null
    }
    {
      (!user.is_pro || user.roommate_access) ?
        <NavLink className="link" to="/my-rooms" activeClassName="active" exact={true}>
          <i className="nc-icon-mini ui-2_paragraph"></i>
          My Rooms
        </NavLink>
        : null
    }
    <NavLink className="link" to="/my-viewings" activeClassName="active" exact={true}>
      <i className="nc-icon-mini ui-1_calendar-60"></i>
      My Viewings
    </NavLink>
    {
      !user.is_pro ?
        <NavLink className="link" to="/favorite-listings" activeClassName="active" exact={true}>
          <i className="nc-icon-mini ui-3_heart"></i>
          Favorite Listings
        </NavLink>
        : null
    }
    <NavLink className="link" to="/account-settings" activeClassName="active" exact={true}>
      <i className="nc-icon-mini ui-1_settings-gear-65"></i>
      Account Settings
    </NavLink>
    {
      user.id && !user.is_pro && user.has_mates_profile ?
        <NavLink className="link" to="/mates-settings" activeClassName="active" exact={true}>
          <i className="nc-icon-mini users_multiple-11"></i>
          Mates Settings
        </NavLink>
        : null
    }
    <span className="link" onClick={logout}>
      <i className="nc-icon-mini arrows-2_log-out"></i>
      Sign Out
    </span>
  </div>
)
