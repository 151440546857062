import qs from 'qs'
import { push } from 'react-router-redux'

import { RequestService } from '../store'
import saleService from '../services/saleService'
import userService from '../services/userService'

export const getSale = saleId => {
  return {
    type: 'GET_SALE',
    payload: saleService.getListing(saleId, {
      with_similar_neighborhoods: true,
    }),
  }
}

export const removeSale = saleId => {
  return {
    type: 'REMOVE_SALE',
    payload: saleService.removeListing(saleId),
  }
}

export const extendSale = data => {
  return {
    type: 'EXTEND_SALE',
    payload: saleService.extendListing(data),
  }
}

export const sendSellContact = data => {
  return {
    type: 'SEND_SELL_CONTACT',
    payload: saleService.sendSellContact(data),
  }
}
export const sendRebateContact = data => {
  return {
    type: 'SEND_REBATE_CONTACT',
    payload: saleService.sendRebateContact(data),
  }
}

export const getEditSale = saleId => {
  return {
    type: 'GET_EDIT_SALE',
    payload: saleService.getListing(saleId),
  }
}

export const resetEditSale = () => {
  return {
    type: 'GET_EDIT_SALE_FULFILLED',
    payload: null,
  }
}

export const saveSale = sale => {
  return (dispatch => {
    dispatch({
      type: 'SAVE_SALE_PENDING',
    })

    saleService.saveSale(sale).then(data => {
      dispatch({
        type: 'SAVE_SALE_FULFILLED',
      })
      dispatch(push('/my-listings'))

      let user = RequestService.getUser()
      user.pcc = data.new_pcc_amount
      RequestService.setUser(user)

      dispatch({
        type: 'SET_USER',
        payload: user,
      })
    }, err => {
      dispatch({
        type: 'SAVE_SALE_REJECTED',
        payload: err,
      })
    })
  })
}

export const updateSale = sale => {
  return (dispatch => {
    dispatch({
      type: 'UPDATE_SALE_PENDING',
    })

    saleService.updateSale(sale).then(data => {
      dispatch({
        type: 'UPDATE_SALE_FULFILLED',
      })
      dispatch(push('/my-listings'))
    }, err => {
      dispatch({
        type: 'UPDATE_SALE_REJECTED',
        payload: err,
      })
    })
  })
}

export const resetSale = () => {
  return {
    type: 'RESET_SALE',
  }
}

export const resetBrowseSales = () => {
  saleService.cancelGetBrowseSales()

  return {
    type: 'RESET_BROWSE_SALES',
  }
}

export const getMySales = loadedListings => {
  return {
    type: 'GET_MY_PRO_SALES',
    payload: saleService.getMyProSales(loadedListings),
  }
}

export const resetMySales = () => {
  return {
    type: 'RESET_MY_SALES',
  }
}

export const toggleFavoriteSale = (user, sale) => {
  if (user) {
    return {
      type: 'FAVORITE_SALE',
      payload: userService.favorite(null, sale.id),
      meta: {
        sale,
      },
    }
  } else {
    return {
      type: 'SET_LOGIN_MODAL_VISIBILITY',
      payload: true,
    }
  }
}

export const getFavoriteSales = () => {
  return {
    type: 'GET_FAVORITE_SALES',
    payload: saleService.getFavoriteSales(),
  }
}

export const resetFavoriteSales = () => {
  return {
    type: 'RESET_FAVORITE_SALES',
  }
}

export const getHomepageSales = filters => {
  return {
    type: 'GET_HOMEPAGE_SALES',
    payload: saleService.getHomepageListings(filters),
  }
}

const getFiltersFromUrl = (queryString, extraFilters) => {
  const queryFilters = qs.parse(decodeURI(queryString), {
    decoder: str => {
      const floatStr = parseFloat(str)

      if (!isNaN(floatStr) && str === floatStr) {
        str = floatStr
      } else if (str === 'true') {
        str = true
      } else if (str === 'false') {
        str = false
      }

      return str
    },
    allowDots: true,
    arrayLimit: 200,
  }) || {}

  const filterBy = queryFilters.filter_by || {}

  return {
    page: queryFilters.page || 1,
    filter_by: {
      address: filterBy.address || undefined,
      amenities: (filterBy.amenities && filterBy.amenities.map(str => parseInt(str, 10))) || [],
      bathrooms: (filterBy.bathrooms && filterBy.bathrooms.map(str => parseInt(str, 10))) || [],
      bathroom: (filterBy.bathroom && parseInt(filterBy.bathroom, 10)) || undefined,
      bbox: filterBy.bbox || {},
      bedrooms: (filterBy.bedrooms && filterBy.bedrooms.map(str => parseInt(str, 10))) || [],
      bedroom: (filterBy.bedroom && parseInt(filterBy.bedroom, 10)) || undefined,
      neighbourhood: filterBy.neighbourhood || [],
      listed_by: filterBy.listed_by || undefined,
      pet_policy: filterBy.pet_policy || undefined,
      polygon: filterBy.polygon || [],
      price: filterBy.price || {},
      subway_lines: filterBy.subway_lines || [],
      zones: filterBy.zones || undefined,
      open_houses: filterBy.open_houses || undefined,
      building_types: filterBy.building_types || [],
      zone_slug: filterBy.zone_slug || undefined,
      penthouses: filterBy.penthouses || undefined,
      amenities_slug: filterBy.amenities_slug || [],
      ...extraFilters,
    },
    sort_by: queryFilters.sort_by || undefined,
  }
}

export const getBrowseSales = (queryString, extraFilters, isLimitedPage) => {
  const filters = getFiltersFromUrl(queryString, extraFilters)

  if (isLimitedPage && filters.page > 10) {
    return {
      type: 'SET_PAGE_STATUS',
      payload: 404,
    }
  }

  return {
    type: 'GET_BROWSE_SALES',
    payload: saleService.getBrowseSales(filters),
    meta: {
      filters,
    },
  }
}

export const pendingBrowseSales = () => {
  return {
    type: 'GET_BROWSE_SALES_PENDING',
  }
}

export const getSalePriceStats = (queryString, extraFilters) => {
  let filters = getFiltersFromUrl(queryString, extraFilters)

  filters.page = undefined
  filters.filter_by.price = {}

  return {
    type: 'GET_SALE_PRICE_STATS',
    payload: saleService.getSalePriceStats(filters),
  }
}

export const getSaleCount = (queryString, extraFilters) => {
  let filters = getFiltersFromUrl(queryString, extraFilters)

  filters.page = undefined

  return {
    type: 'GET_SALE_COUNT',
    payload: saleService.getSaleCount(filters),
  }
}

export const getSimilarSales = saleId => {
  return {
    type: 'GET_SIMILAR_SALES',
    payload: saleService.getSimilarSales(saleId),
  }
}

export const openMoreSaleFilters = () => {
  return {
    type: 'OPEN_MORE_SALE_FILTERS',
  }
}

export const closeMoreSaleFilters = () => {
  return {
    type: 'CLOSE_MORE_SALE_FILTERS',
  }
}

export const setMoreSaleFilters = values => {
  return {
    type: 'SET_MORE_SALE_FILTERS',
    payload: values,
  }
}
