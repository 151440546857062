/* global __CLIENT__ */

import { compose, applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import { routerMiddleware } from 'react-router-redux'
// import logger from 'redux-logger'

import reducer from './reducers'
import RS from './services/requestService'

const configureStore = (initialState, history) => {

  // Create router middleware based on browser/memory history
  const historyMiddleware = routerMiddleware(history)
  const composedStore = compose(
    applyMiddleware(
      historyMiddleware,
      thunk,
      promiseMiddleware(),
      // logger,
    ),
    __CLIENT__ && window.devToolsExtension ? window.devToolsExtension() : f => f
  )(createStore)

  const store = composedStore(reducer, initialState)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers/index')
      store.replaceReducer(nextReducer)
    })
  }

  return store
}

export const RequestService = new RS(configureStore)

export default configureStore
