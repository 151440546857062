import { RequestService } from '../store'

class NeighborhoodService {
  getAllNeighborhoodGuide(params) {
    return RequestService.get('/neighbourhood/flatten', {
      guide: true,
      ...params,
    })
  }

  getAllGroupedNeighborhoods() {
    return RequestService.get('/neighbourhood')
  }

  getOneNeighborhoodGuide(name, query) {
    return RequestService.get(`/neighbourhood/${name}`, query)
  }

  getNeighborhoodLink(slug) {
    return RequestService.get(`/neighbourhood_links/${slug}`)
  }

  getSimilarNeighborhoods(slug, count) {
    return RequestService.get(`/neighbourhood/${slug}/similar`, {
      count: count || 12
    })
  }
}

export default new NeighborhoodService()
