const defaultState = {
  errorStatus: null,
  mobileMenuVisible: false,
  listingPhotoCarouselIndex: 0,
  zones: [],
  pageStatus: 200,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_ERROR_STATUS': {
      state = {
        ...state,
        errorStatus: action.payload,
      }
      break
    }
    case 'SET_MOBILE_MENU_VISIBILITY': {
      state = {
        ...state,
        mobileMenuVisible: action.payload,
      }
      break
    }
    case '@@router/LOCATION_CHANGE': {
      state = {
        ...state,
        mobileMenuVisible: false,
      }
      break
    }
    case 'SET_LISTING_PHOTO_CAROUSEL_INDEX': {
      state = {
        ...state,
        listingPhotoCarouselIndex: action.payload,
      }
      break
    }
    case 'GET_SEO_CONTENT_PENDING': {
      state = {
        ...state,
        seoContentPending: true,
      }
      break
    }
    case 'GET_SEO_CONTENT_FULFILLED': {
      state = {
        ...state,
        seoContentPending: false,
        seoContent: action.payload,
      }
      break
    }
    case 'GET_SEO_CONTENT_REJECTED': {
      state = {
        ...state,
        pageStatus: 404,
        seoContentPending: false,
      }
      break
    }
    case 'GET_ZONES_FULFILLED': {
      state = {
        ...state,
        zones: [...action.payload],
      }
      break
    }
    case 'SET_PAGE_STATUS': {
      state = {
        ...state,
        pageStatus: action.payload,
      }
      break
    }
    case 'GET_ARTICLE_REJECTED':
    case 'GET_NEIGHBORHOOD_LINK_REJECTED':
    case 'GET_SALE_REJECTED':
    case 'GET_RENTAL_REJECTED':
    case 'GET_ONE_GUIDE_REJECTED':
    case 'GET_BUILDING_REJECTED':
    case 'GET_BROKER_REJECTED': {
      state = {
        ...state,
        pageStatus: 404,
      }
      break
    }
    case 'GET_BROWSE_SALES_REJECTED':
    case 'GET_BROWSE_RENTALS_REJECTED': {
      if (action.payload && action.payload.status === 404) {
        state = {
          ...state,
          pageStatus: 404,
        }
      }
      break
    }
    default: {
      break
    }
  }

  return state
}
