import axios from 'axios'

import { RequestService } from '../store'

import * as constants from '../helpers/constants'

const CancelToken = axios.CancelToken
let cancel

class ArticleService {
  cancelGetBrowseArticles() {
    if (cancel) {
      cancel()
    }
  }

  getBrowseArticles(filters) {
    if (cancel) {
      cancel()
    }

    return RequestService.get('/article', {
        total_items: constants.browseArticlesPerPage,
        page: (filters && filters.page) || 1,
        ...(filters.tag && {tag: filters.tag}),
        ...(filters.author && {author: filters.author || ''}),
      }, null, {
        cancelToken: new CancelToken(function executor (c) {
          cancel = c;
        }),
      },
    )
  }

  getArticles() {
    return RequestService.get('/article')
  }

  getSimilarArticles(articleSlug) {
    return RequestService.get('/article/' + articleSlug + '/similar', {
      is_slug: true,
    })
  }

  getArticle(articleSlug) {
    return RequestService.get('/article/' + articleSlug, {
      is_slug: true,
    })
  }

  getFeaturedArticles() {
    return RequestService.get('/article', {
      featured: true,
    })
  }

  getArticleTag(slug) {
    return RequestService.get(`/article_tags/${slug}`)
  }
}

export default new ArticleService()
